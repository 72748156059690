/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type MonthByMonthDownload_viewer$ref = any;
export type GLOBAL_FILTER_CONTEXT = "INVOICES" | "LABELS" | "PEOPLE" | "PLACEHOLDERS" | "PROJECTS" | "RESOURCES" | "SKILLS" | "TASKS" | "TIME_REGS" | "%future added value";
export type GLOBAL_FILTER_FIELD = "APPROVAL_STATUS" | "ASSIGNED" | "ASSIGNED_SELF" | "BILLABLE" | "BILLABLE_TIME_REG" | "BILL_FROM" | "BILL_TO" | "BLOCKED" | "BUG" | "CLIENT" | "CLIENT_GUEST_USERS" | "CLIENT_TIME_REG" | "COMPANY_TASK_ID" | "CONTACT" | "CREATED_AT" | "DEADLINE" | "DEPARTMENT" | "DEPARTMENT_TIME_REG" | "DEPENDENCIES" | "DESCRIPTION" | "END_DATE" | "END_DATE_PROJECT" | "END_DATE_TIME_REG" | "FILTER_BY_TIME_REG_SETTINGS" | "FOLLOWER" | "FORECAST" | "HIGH_PRIORITY" | "INTERNAL_TIME" | "INTERNAL_TIME_REG" | "INVOICED" | "LABELS" | "LABEL_TIME_REG" | "NAME" | "NON_CLIENT_TIME_REG" | "NON_DEPARTMENT_TIME_REG" | "NON_PHASE_TIME_REG" | "NON_PROJECT_TIME_REG" | "NON_ROLE_TIME_REG" | "NON_SPRINT_TIME_REG" | "NON_TASK_TIME_REG" | "NO_CONTACT" | "OWNER" | "PERMISSION_LEVEL" | "PERSON" | "PERSON_TIME_REG" | "PHASE_CATEGORY" | "PHASE_TIME_REG" | "PRIORITY_LEVEL" | "PROGRAM" | "PROJECT" | "PROJECT_BUDGET_TYPE" | "PROJECT_DEFAULT_PERIOD_BUDGET_TYPE" | "PROJECT_FOLLOWER" | "PROJECT_GROUP" | "PROJECT_GROUP_SPRINT" | "PROJECT_GROUP_STATUS" | "PROJECT_LABELS" | "PROJECT_MANUAL_PROGRESS" | "PROJECT_OWNER" | "PROJECT_PERSON" | "PROJECT_PHASE" | "PROJECT_SPRINT" | "PROJECT_STAGE" | "PROJECT_STATUS" | "PROJECT_TIME_REG" | "RATE_CARD" | "RECENT_ACTIVITY" | "RECENT_TASK_ACTIVITY" | "RECENT_TIME" | "REMAINING" | "RETAINER_PERIOD" | "ROLE" | "ROLE_TIME_REG" | "SET_DONE_AT" | "SKILL" | "SPRINT_CATEGORY" | "SPRINT_TIME_REG" | "STARRED" | "START_DATE" | "START_DATE_PROJECT" | "START_DATE_TIME_REG" | "STATUS" | "SUB_TASKS" | "TASK" | "TASK_APPROVED" | "TASK_HIERARCHY" | "TASK_LEVEL" | "TASK_TIME_REG" | "TEAM" | "TEAM_MEMBER" | "TEAM_TIME_REG" | "TIME_OFF" | "TIME_OFF_TIME_REG" | "TIME_REGS" | "UPDATED_AT" | "WITH_TASKS" | "WITH_TIME_REG" | "%future added value";
export type GLOBAL_FILTER_LOGICAL_OPERATOR = "AND" | "OR" | "%future added value";
export type GLOBAL_FILTER_OPERATOR = "ALL_IN" | "CONTAINS" | "CONTAINS_NOT" | "GREATER" | "GREATER_OR_EQUAL" | "IN" | "IS" | "IS_ALL" | "IS_NOT" | "IS_NOT_ALL" | "LESS" | "LESS_OR_EQUAL" | "NOT_ALL_IN" | "NOT_IN" | "%future added value";
export type TaskSearchQueryType = {|
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>
|};
export type GlobalSearchFilterType = {|
  field?: ?GLOBAL_FILTER_FIELD,
  operator?: ?GLOBAL_FILTER_OPERATOR,
  value?: ?$ReadOnlyArray<?string>,
  context?: ?GLOBAL_FILTER_CONTEXT,
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>,
  logicalOperator?: ?GLOBAL_FILTER_LOGICAL_OPERATOR,
|};
export type MonthByMonthDownload_QueryVariables = {|
  startYear?: ?number,
  startMonth?: ?number,
  startDay?: ?number,
  endYear?: ?number,
  endMonth?: ?number,
  endDay?: ?number,
  searchQuery: TaskSearchQueryType,
  pageSize: number,
  cursor?: ?string,
|};
export type MonthByMonthDownload_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: MonthByMonthDownload_viewer$ref,
  |}
|};
export type MonthByMonthDownload_Query = {|
  variables: MonthByMonthDownload_QueryVariables,
  response: MonthByMonthDownload_QueryResponse,
|};
*/


/*
query MonthByMonthDownload_Query(
  $startYear: Int
  $startMonth: Int
  $startDay: Int
  $endYear: Int
  $endMonth: Int
  $endDay: Int
  $searchQuery: TaskSearchQueryType!
  $pageSize: Int!
  $cursor: String
) {
  viewer {
    actualPersonId
    component(name: "utilization_report_month_by_month_person_csv_loader")
    ...MonthByMonthDownload_viewer_2Tl75
    id
  }
}

fragment MonthByMonthDownload_viewer_2Tl75 on Viewer {
  company {
    resourceBasedPersons(first: $pageSize, after: $cursor, searchQuery: $searchQuery, startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, aggregateLevel: MONTH, aggregates: ["forecastBillableUtilization", "forecastBillableUtilizationWin", "forecastBillableUtilizationSoft", "forecastBillableUtilizationSoftWin", "forecastBillableUtilizationHard", "forecastResourceUtilization", "forecastResourceUtilizationWin", "forecastResourceUtilizationSoft", "forecastResourceUtilizationSoftWin", "forecastResourceUtilizationHard", "forecastBillableUtilizationTaskAndAllocationsCombined", "forecastBillableUtilizationTaskAndAllocationsCombinedWin", "forecastBillableUtilizationTaskAndAllocationsCombinedSoft", "forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin", "forecastBillableUtilizationTaskAndAllocationsCombinedHard", "forecastResourceUtilizationTaskAndAllocationsCombined", "forecastResourceUtilizationTaskAndAllocationsCombinedWin", "forecastResourceUtilizationTaskAndAllocationsCombinedSoft", "forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin", "forecastResourceUtilizationTaskAndAllocationsCombinedHard"]) {
      edges {
        node {
          id
          firstName
          lastName
          profilePictureId
          aggregatedResourceNumbers
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startYear",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startMonth",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startDay",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endYear",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endMonth",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endDay",
    "type": "Int"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchQuery",
    "type": "TaskSearchQueryType!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor",
    "type": "String"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "utilization_report_month_by_month_person_csv_loader"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"utilization_report_month_by_month_person_csv_loader\")"
},
v3 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v4 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v5 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v6 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v7 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v8 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v9 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MonthByMonthDownload_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": [
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "Variable",
                "name": "pageSize",
                "variableName": "pageSize"
              },
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "MonthByMonthDownload_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MonthByMonthDownload_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "after",
                    "variableName": "cursor"
                  },
                  {
                    "kind": "Literal",
                    "name": "aggregateLevel",
                    "value": "MONTH"
                  },
                  {
                    "kind": "Literal",
                    "name": "aggregates",
                    "value": [
                      "forecastBillableUtilization",
                      "forecastBillableUtilizationWin",
                      "forecastBillableUtilizationSoft",
                      "forecastBillableUtilizationSoftWin",
                      "forecastBillableUtilizationHard",
                      "forecastResourceUtilization",
                      "forecastResourceUtilizationWin",
                      "forecastResourceUtilizationSoft",
                      "forecastResourceUtilizationSoftWin",
                      "forecastResourceUtilizationHard",
                      "forecastBillableUtilizationTaskAndAllocationsCombined",
                      "forecastBillableUtilizationTaskAndAllocationsCombinedWin",
                      "forecastBillableUtilizationTaskAndAllocationsCombinedSoft",
                      "forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin",
                      "forecastBillableUtilizationTaskAndAllocationsCombinedHard",
                      "forecastResourceUtilizationTaskAndAllocationsCombined",
                      "forecastResourceUtilizationTaskAndAllocationsCombinedWin",
                      "forecastResourceUtilizationTaskAndAllocationsCombinedSoft",
                      "forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin",
                      "forecastResourceUtilizationTaskAndAllocationsCombinedHard"
                    ]
                  },
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "pageSize"
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "concreteType": "PersonTypeConnection",
                "kind": "LinkedField",
                "name": "resourceBasedPersons",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PersonTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Person",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "profilePictureId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "aggregatedResourceNumbers",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "MonthByMonthDownload_Query",
    "operationKind": "query",
    "text": "query MonthByMonthDownload_Query(\n  $startYear: Int\n  $startMonth: Int\n  $startDay: Int\n  $endYear: Int\n  $endMonth: Int\n  $endDay: Int\n  $searchQuery: TaskSearchQueryType!\n  $pageSize: Int!\n  $cursor: String\n) {\n  viewer {\n    actualPersonId\n    component(name: \"utilization_report_month_by_month_person_csv_loader\")\n    ...MonthByMonthDownload_viewer_2Tl75\n    id\n  }\n}\n\nfragment MonthByMonthDownload_viewer_2Tl75 on Viewer {\n  company {\n    resourceBasedPersons(first: $pageSize, after: $cursor, searchQuery: $searchQuery, startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, aggregateLevel: MONTH, aggregates: [\"forecastBillableUtilization\", \"forecastBillableUtilizationWin\", \"forecastBillableUtilizationSoft\", \"forecastBillableUtilizationSoftWin\", \"forecastBillableUtilizationHard\", \"forecastResourceUtilization\", \"forecastResourceUtilizationWin\", \"forecastResourceUtilizationSoft\", \"forecastResourceUtilizationSoftWin\", \"forecastResourceUtilizationHard\", \"forecastBillableUtilizationTaskAndAllocationsCombined\", \"forecastBillableUtilizationTaskAndAllocationsCombinedWin\", \"forecastBillableUtilizationTaskAndAllocationsCombinedSoft\", \"forecastBillableUtilizationTaskAndAllocationsCombinedSoftWin\", \"forecastBillableUtilizationTaskAndAllocationsCombinedHard\", \"forecastResourceUtilizationTaskAndAllocationsCombined\", \"forecastResourceUtilizationTaskAndAllocationsCombinedWin\", \"forecastResourceUtilizationTaskAndAllocationsCombinedSoft\", \"forecastResourceUtilizationTaskAndAllocationsCombinedSoftWin\", \"forecastResourceUtilizationTaskAndAllocationsCombinedHard\"]) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          profilePictureId\n          aggregatedResourceNumbers\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a09c78b917fe8a4ba0dd90edf620e39b';

module.exports = node;
