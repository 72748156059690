import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {filterAggregateFields, isColumnLocked, TABLE_TYPE} from '../util/BudgetUtils';
import {createFragmentContainer, graphql} from 'react-relay';
import {CollapsibleSectionWrapper, TableSectionWrapper} from '../money/work_and_expenses/TableSection.styled';
import RevenueRecognitionTableRow from './RevenueRecognitionTableRow';
import {LoadMore} from '../../../../loaders/LoadMore';
import RevenueRecognitionDetailsLoader, {
	revenueRecognitionDetailsLoaderQuery,
} from '../loaders/RevenueRecognitionDetailsLoader';
import {Collapsible, CustomScrollDiv, LockIcon, Table, useCollapsible} from 'web-components';
import {SPECIAL_ROLE} from '../../../../../constants';
import getAggregateRowTitle from '../util/UseAggregateIntl';
import {RevenueRecognitionTableContainer, RevenueRecognitionTableHeadline} from './RevenueRecognition.styled';
import {ForecastTooltipFormulaRenderer} from '../../../../ForecastTooltipFormulaRenderer';
import ForecastTooltip from '../../../../../forecast-app/shared/components/tooltips/ForecastTooltip';
import {RevenueRecognitionTooltips} from './RevenueRecognitionTooltips';
import ManualRevenueRecognitionTableRow from './ManualRevenueRecognitionTableRow';
import ManualRevenueRecognitionResetRow from './ManualRevenueRecognitionResetRow';
import Util from '../../../../../forecast-app/shared/util/util';
import {HeaderText} from './RevenueRecognition.styled';
import {hasFeatureFlag} from '../../../../../forecast-app/shared/util/FeatureUtil';

const TimeRevenueRecognition = ({project, retry, timestamp, isManualProgressUpdating, setManualProgressUpdating}) => {
	const intl = useIntl();
	const {formatMessage} = intl;

	const [tableHeight, setTableHeight] = useState(400);

	const hasFinancialCategoriesUpdate = hasFeatureFlag('financial_categories_update');

	const revenueAggregates = filterAggregateFields(project, [
		'suggestedRevenue',
		'suggestedRevenuePercentage',
		'suggestedProfit',
		'suggestedProfitPercentage',
	]);
	const manualRecognitionAggregates = filterAggregateFields(project, ['recognitionAmount', 'recognitionPercentage']);
	const billableTimeAggregates = filterAggregateFields(
		project,
		hasFinancialCategoriesUpdate ? ['billableTime'] : ['billableTime', 'totalProfitAtCompletion', 'totalMarginAtCompletion']
	);
	const varianceAndAdjustmentAggregates = filterAggregateFields(project, ['surplus', 'surplusPercentage']);
	const costAggregates = filterAggregateFields(project, ['estimatedCost', 'estimatedCostPercentage']);
	const allAggregates = revenueAggregates
		.concat(manualRecognitionAggregates)
		.concat(billableTimeAggregates)
		.concat(varianceAndAdjustmentAggregates)
		.concat(costAggregates);

	const searchQuery = {
		filters: [
			{
				field: 'ROLE',
				operator: 'NOT_IN',
				value: SPECIAL_ROLE.EXPENSE,
			},
		],
	};

	const expenseSearchQuery = {
		filters: [
			{
				field: 'ROLE',
				operator: 'IN',
				value: SPECIAL_ROLE.EXPENSE,
			},
		],
	};

	return (
		<CollapsibleSectionWrapper>
			<Collapsible
				initiallyCollapsed={true}
				title={formatMessage({id: 'project_budget.recognition_revenue_table_headline.recognition_time_table'})}
				localStorageKey={'project_budget.collapse_time_recognition_table'}
				cy={'time-revenue-recognition-collapse'}
			>
				<TableSectionWrapper>
					<LoadMore
						key="query-render-revenue-recognition-details"
						query={revenueRecognitionDetailsLoaderQuery}
						loader={
							<RevenueRecognitionDetailsLoader
								aggregateLevel={'MONTH'}
								revenueAggregates={revenueAggregates}
								manualRecognitionAggregates={manualRecognitionAggregates}
								costAggregates={costAggregates}
								billableTimeAggregates={billableTimeAggregates}
								varianceAndAdjustmentAggregates={varianceAndAdjustmentAggregates}
								allAggregates={allAggregates}
								tableType={TABLE_TYPE.TIME_REVENUE_RECOGNITION}
								retry={retry}
								timestamp={timestamp}
							/>
						}
						variables={{
							projectId: project.id,
							startYear: project.projectRevRecFirstDateYear,
							startMonth: project.projectRevRecFirstDateMonth,
							startDay: project.projectRevRecFirstDateDay,
							endYear: project.projectRevRecLastDateYear,
							endMonth: project.projectRevRecLastDateMonth,
							endDay: project.projectRevRecLastDateDay,
							aggregateLevel: 'MONTH',
							aggregates: allAggregates,
							searchQuery: searchQuery,
							expenseSearchQuery: expenseSearchQuery,
						}}
					>
						{data => {
							const calculateTableHeight = () => {
								const isTableShown = table => {
									const isShown = Util.localStorageGetItem(table.headlineTextId) !== 'true';
									return isShown;
								};

								const rowAmount = data.tables.reduce(
									(total, table) => total + (isTableShown(table) ? table.rows.length : 0),
									0
								);
								return data.tables.length * 78 + rowAmount * 48 + 15;
							};
							setTableHeight(calculateTableHeight());

							return (
								<CustomScrollDiv horizontalContent autoHeight={true} autoHeightMin={tableHeight}>
									{data.tables.map(table => {
										const title = (
											<RevenueRecognitionTableHeadline>
												{formatMessage({id: table.headlineTextId})}
											</RevenueRecognitionTableHeadline>
										);
										const {Collapser, collapsed} = useCollapsible({
											initiallyCollapsed: false,
											localStorageKey: table.headlineTextId,
											title,
										});
										useEffect(() => {
											setTableHeight(calculateTableHeight());
										}, [collapsed]);
										return (
											<CollapsibleSectionWrapper>
												<RevenueRecognitionTableContainer>
													<Table paddedCells>
														<Table.Header>
															{table.showHeaderRow ? (
																<Table.HeaderColumn visible flex={1} width="EXTRA_LARGE" />
															) : (
																<Table.HeaderColumn visible flex={1} width="EXTRA_LARGE">
																	<Collapser />
																</Table.HeaderColumn>
															)}
															{table.columns.map((column, index) => (
																<Table.HeaderColumn
																	key={
																		formatMessage({id: 'common.revenue_recognition'}) +
																		column.name
																	}
																	usePadding
																	align="right"
																	width="MEDIUM_SMALL"
																	visible
																	wrapText={true}
																>
																	<Table.HeaderColumn.Title bold={index === table.length - 1}>
																		{table.showHeaderRow ? (
																			<>
																				{isColumnLocked(
																					project.fixedPriceLocks,
																					column
																				) && (
																					<div style={{marginRight: 10}}>
																						<LockIcon
																							color={'#C4C4CC'}
																							size={LockIcon.SIZE.STANDARD}
																						/>
																					</div>
																				)}
																				<HeaderText>{column.name}</HeaderText>
																			</>
																		) : null}
																	</Table.HeaderColumn.Title>
																</Table.HeaderColumn>
															))}
														</Table.Header>
														{table.showHeaderRow ? (
															<Table.Header>
																<Table.HeaderColumn visible flex={1} width="EXTRA_LARGE">
																	<Collapser />
																</Table.HeaderColumn>
																{table.columns.map((column, index) => (
																	<Table.HeaderColumn
																		usePadding
																		align="right"
																		width="MEDIUM_SMALL"
																		visible
																	></Table.HeaderColumn>
																))}
															</Table.Header>
														) : null}
														<Table.Rows data={collapsed ? [] : table} canExpand={false}>
															{({rowData, tableColumnsProps}) => {
																const tooltipItems =
																	RevenueRecognitionTooltips.TimeRevenueRecognitionTooltips[
																		rowData.aggregate
																	];
																return rowData.aggregate === 'manualRecognitionAmount' ? (
																	<ManualRevenueRecognitionTableRow
																		data={table}
																		rowData={rowData}
																		tableColumnProps={tableColumnsProps}
																		project={project}
																		setUpdating={setManualProgressUpdating}
																		isUpdating={isManualProgressUpdating}
																	/>
																) : rowData.aggregate === 'manualRecognitionReset' ? (
																	<ManualRevenueRecognitionResetRow
																		data={table}
																		rowData={rowData}
																		tableColumnProps={tableColumnsProps}
																		project={project}
																	/>
																) : (
																	<RevenueRecognitionTableRow
																		data={table}
																		rowData={rowData}
																		tableColumnProps={tableColumnsProps}
																		project={project}
																		headerColumn={
																			<Table.Column wrapText={true}>
																				<ForecastTooltip
																					maxWidth={600}
																					content={
																						tooltipItems ? (
																							<ForecastTooltipFormulaRenderer
																								items={tooltipItems}
																								translatedMessage={true}
																							/>
																						) : null
																					}
																				>
																					{getAggregateRowTitle(
																						intl,
																						rowData.aggregate,
																						TABLE_TYPE.TIME_REVENUE_RECOGNITION
																					)}
																				</ForecastTooltip>
																			</Table.Column>
																		}
																	/>
																);
															}}
														</Table.Rows>
													</Table>
												</RevenueRecognitionTableContainer>
											</CollapsibleSectionWrapper>
										);
									})}
								</CustomScrollDiv>
							);
						}}
					</LoadMore>
				</TableSectionWrapper>
			</Collapsible>
		</CollapsibleSectionWrapper>
	);
};

export default createFragmentContainer(TimeRevenueRecognition, {
	project: graphql`
		fragment TimeRevenueRecognition_project on ProjectType {
			id
			billable
			budgetWork
			budgetType
			defaultPeriodBudgetType
			projectRevRecFirstDateYear
			projectRevRecFirstDateMonth
			projectRevRecFirstDateDay
			projectRevRecLastDateYear
			projectRevRecLastDateMonth
			projectRevRecLastDateDay
			fixedPriceLocks(first: 1000) @connection(key: "Project_fixedPriceLocks", filters: []) {
				edges {
					node {
						id
						startDate
						endDate
						locked
					}
				}
			}
		}
	`,
});
