import React from 'react';
import {
	applySelectedEyeOption,
	getMomentFromCanvasTimelineDate,
	getPhaseItems,
	getVisualizationMode,
	GROUP_TYPE,
	isProjectDoneOrHalted,
	isTimeOffAllocation,
	ITEM_DRAG_POINT,
	ITEM_TYPE,
	moveItem,
	TOOLTIP_DISPLAY_DELAY_MS,
	VISUALIZATION_MODE,
	isProgramRelation,
	clearDrawnStepDataArrayMap,
	clearDrawnStepsMap,
} from './canvas-timeline/canvas_timeline_util';
import {
	BUTTON_COLOR,
	BUTTON_STYLE,
	DATE_FORMAT_DAY,
	PROJECT_STATUS,
	SCHEDULING_ACTION_MENU_TYPE,
	SCHEDULING_VIEW,
} from '../../constants';
import {hasFeatureFlag} from '../../forecast-app/shared/util/FeatureUtil';
import DataManager, {DATA_ENTITIES} from './DataManager';
import {clearPagecomponentHeatmapCache, getRecalculationInterval, recalculateGroupHeatmapCache} from './heatmap/HeatmapLogic';
import Util from '../../forecast-app/shared/util/util';
import tracking from '../../tracking';
import {SUPER_PROPERTY, trackComplexEvent, trackEvent, updateSuperProperty} from '../../tracking/amplitude/TrackingV2';
import {MODAL_TYPE, showModal} from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import Warning from '../warning';
import ComposeManager from './ComposeManager';
import {trackPersonHeatmapTotalsPerformance} from './canvas-timeline/canvas_timeline_performance_track';
import {
	getEventTrackingMessage,
	getEyeOptionStorageKey,
	getFilterStorageKey,
	getGroupByOptions,
	getGroupByStorageKey,
	getSortByStorageKey,
	getUtilizationFormatStorageKey,
	getVisualizationModeStorageKey,
	isPhaseItem,
	isProjectAllocationItem,
	isTaskItem,
	shouldDisplayActionMenu,
} from './SchedulingUtils';
import {
	ACTION_MENU_OPTION_TYPE,
	DATE_FROM_PRIORITY,
	DISTRIBUTION_TYPE,
	EYE_OPTION_NAME,
	PROJECT_ENTITY_GROUP_TYPE,
	PROJECT_SUB_GROUP_TYPE,
	SCHEDULE_ITEM_GHOST,
} from './constants';
import {getSuggestedPersonsByFilter} from './placeholders-scheduling/CanvasPlaceholdersFetchUtil';
import {handleAssignToPerson} from './actions/handle_assign_to_person';
import {handleReplacePlaceholderWithPerson} from './actions/handle_replace_placeholder_with_person';
import {handleDeletePlaceholder} from './actions/handle_delete_placeholder';
import {handleAddPersonToProject} from './actions/handle_add_person_to_project';
import {interactionManager} from './canvas-timeline/canvas_timeline_interaction_manager';
import {displayPlaceholderInformation, displayProjectInformation} from './canvas-timeline/tooltip_information';
import {onAllocationContextMenu, onPlaceholderAllocationContextMenu, onTaskContextMenu} from './canvas_scheduling_context_menu';
import {handleViewProject} from './actions/handle_view_project';
import {handleRemovePerson} from './actions/handle_remove_person';
import ProgramUtil from '../../forecast-app/shared/util/ProgramUtil';
import {handleSetProjectStatus} from './actions/handle_set_project_status';
import {handleDeleteProject} from './actions/handle_delete_project';
import {addToChangeList, cleanUpGhosting, createGhost} from './placeholders-scheduling/CanvasPlaceholdersSchedulingUtil';
import {STAFFING_CHANGE_LIST_ENTITIES} from './placeholders-scheduling/CanvasPlaceholderSchedulingConstants';
import IDManager from './IDManager';
import {hasPermission} from '../../forecast-app/shared/util/PermissionsUtil';
import {PERMISSION_TYPE} from '../../Permissions';
import {isTaskInHierarchy} from './projects-scheduling/projects_scheduling_util';
import {getRelevantDependencyTaskIdSet} from './projects-scheduling/projects_scheduling_dependencies';
import handleDisplayProjectPhaseCapacity from './actions/handle_display_project_phase_capacity';
import MovePhaseOnTimelineMutation from '../../mutations/move_phase_on_timeline_mutation.modern';
import MoveProjectOnTimelineMutation from '../../mutations/move_project_on_timeline_mutation';
import handleDisplayDefaultWarning from './actions/handle_display_default_warning';
import update_program_dates_mutation from '../../mutations/project-service/update_program_dates_mutation';
import ProjectGroup from './components/groups/project_group';
import {filterFreeDragCreatedGroups} from './FilterUtils';
import {canUseAllocationControls} from './components/allocation_controls/AllocationControlsCanvasUtils';
import {handleMutationSuccess} from '../../containers/modal/placeholder/PlaceholderAllocationUtils';

export const EVENT_TYPE = {
	ON_FILTER_CHANGE: 'ON_FILTER_CHANGE',
	ON_GROUP_BY_CHANGE: 'ON_GROUP_BY_CHANGE',
	STAFFING_ROLE_FILTER_REMOVED: 'STAFFING_ROLE_FILTER_REMOVED',
};

const isHoveringGhostOriginGroup = (pageComponent, outerParentGroup) => {
	const {staffingModeActive} = pageComponent.state;
	return staffingModeActive && outerParentGroup === GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP;
};

const findFreeDragOuterParentGroup = (pageComponent, group, item, dragData) => {
	const {initialGroup} = dragData;
	let outerParentGroup = group;

	while (outerParentGroup.parentGroup && !item.isFreeDragOuterGroup(outerParentGroup)) {
		outerParentGroup = outerParentGroup.parentGroup;
	}

	if (!item.isFreeDragOuterGroup(outerParentGroup)) {
		outerParentGroup = initialGroup;

		while (outerParentGroup.parentGroup && !item.isFreeDragOuterGroup(outerParentGroup)) {
			outerParentGroup = outerParentGroup.parentGroup;
		}
	}

	return outerParentGroup;
};

const getAllocationGroupId = (pageComponent, item, outerParentGroup) => {
	const {staffingModeActive} = pageComponent.state;
	const {allocation, placeholder} = item.data;
	const {person} = outerParentGroup.data;
	const {projectId, projectGroupId} = placeholder || allocation || item.data;

	const isPlaceholderAllocation = item.itemType === ITEM_TYPE.PLACEHOLDER_ALLOCATION;
	const isTargetGroupPlaceholderGroup = outerParentGroup.groupType === GROUP_TYPE.CAPACITY_PLACEHOLDER_GROUP;
	if (isPlaceholderAllocation && staffingModeActive && isTargetGroupPlaceholderGroup) {
		return IDManager.getPlaceholderGroupId(pageComponent, placeholder);
	}

	const entityId = person?.id || placeholder?.id;
	return IDManager.getProjectGroupId(pageComponent, entityId, projectGroupId ? null : projectId, projectGroupId, null);
};

const getAllocationGroup = (pageComponent, item, outerParentGroup) => {
	const allocationGroupId = getAllocationGroupId(pageComponent, item, outerParentGroup);

	if (allocationGroupId) {
		const {allocation, placeholder} = item.data;
		const {projectId, projectGroupId} = placeholder || allocation || item.data;

		let allocationGroup = outerParentGroup.groups.find(allocationGroup => allocationGroup.id === allocationGroupId);

		// not already assigned to project, so add project group for person
		if (!allocationGroup && outerParentGroup.groupType === GROUP_TYPE.PERSON) {
			const {person} = outerParentGroup.data;
			const project = projectId && !projectGroupId ? DataManager.getProjectById(pageComponent, projectId) : null;
			const projectGroup = projectGroupId ? DataManager.getProjectGroupById(pageComponent, projectGroupId) : null;

			if (person && (project || projectGroup)) {
				const composedProjectGroupData = ComposeManager.composeProjectGroup(
					pageComponent,
					person,
					null,
					project,
					projectGroup
				);

				if (composedProjectGroupData) {
					composedProjectGroupData.createdByFreeDrag = true;
					allocationGroup = new ProjectGroup(pageComponent, composedProjectGroupData);
					allocationGroup.parentGroup = outerParentGroup;

					const {schedulingView} = pageComponent.props;
					const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;
					if (isPeopleScheduling) {
						// Add before NoContentGroup, NonProjectTimeGroup, PersonAllocationsGroup
						outerParentGroup.addChildGroup(allocationGroup, -3);
					} else {
						// Add before NoContentGroup
						outerParentGroup.addChildGroup(allocationGroup, -1);
					}
				}
			}
		}

		return allocationGroup;
	}

	return null;
};

const getItemGroupsFromDragDestination = (pageComponent, item, allocationGroup, outerParentGroup) => {
	const {schedulingView} = pageComponent.props;

	let itemGroup = allocationGroup;
	let heatmapGroup = outerParentGroup;

	if (allocationGroup && schedulingView === SCHEDULING_VIEW.PEOPLE) {
		const {schedulingOptions} = pageComponent.state;
		const data = pageComponent.getData();
		const {company} = data;
		const isUsingCombinationMode = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.COMBINATION);

		if (isUsingCombinationMode) {
			const isTask = isTaskItem(item);
			const isProjectAllocation = isProjectAllocationItem(item) && !item.data?.allocation?.idleTimeId;

			if (isTask || isProjectAllocation) {
				const entityGroupType = isTask ? PROJECT_ENTITY_GROUP_TYPE.TASK : PROJECT_ENTITY_GROUP_TYPE.ALLOCATION;
				itemGroup = allocationGroup.groups.find(group => group.data.projectEntityGroupType === entityGroupType);
				heatmapGroup = allocationGroup;
			}
		}
	}

	return {
		itemGroup,
		heatmapGroup,
	};
};

const setDestinationGroup = (dragData, outerParentGroup, allocationGroup = null, itemGroup = null) => {
	dragData.destinationGroup = allocationGroup || outerParentGroup;
	dragData.innermostVisibleParentGroup = outerParentGroup;

	if (allocationGroup && outerParentGroup.expanded) {
		dragData.innermostVisibleParentGroup = itemGroup || allocationGroup;
	}
};

const updateItemGroup = (pageComponent, item, heatmapGroup, itemGroup = null) => {
	if (isHoveringGhostOriginGroup(pageComponent, heatmapGroup)) {
		if (item.originalItem) {
			item.groupId = item.originalItem.groupId;
		} else {
			item.groupId = heatmapGroup.id;
		}
	} else if (itemGroup && item.groupId !== itemGroup.id) {
		if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
			// If we are free dragging to new group, remove the PTO allocation from the lookup maps
			// since we are using lookup map data to generate nonWorkingDaysMap
			if (isProjectAllocationItem(item) && isTimeOffAllocation(item.data.allocation)) {
				DataManager.removeFromLookupMaps(pageComponent, DATA_ENTITIES.ALLOCATIONS, item.data.allocation);
			}
			DataManager.moveItemGroupTemporarily(pageComponent, itemGroup, heatmapGroup, item);
		} else {
			item.groupId = itemGroup.id;
			item.resetItemRow();
		}
	}
	if (hasFeatureFlag('improving_heatmap_frontend_performance')) {
		DataManager.recalculateGroupHeatmap(pageComponent, heatmapGroup, item);
	}
};

export default class EventManager {
	static onSearchChange(pageComponent, event) {
		// new navigation returns string in parameter
		const value = event.target ? event.target.value : event;

		pageComponent.setState({searchFilterValue: value}, () => {
			if (pageComponent.state.heatmapFiltering) {
				clearPagecomponentHeatmapCache(pageComponent);
				trackPersonHeatmapTotalsPerformance();
			}

			pageComponent.redrawCanvasTimeline({preventFiltering: false});
		});
	}

	static onEyeOptionsChange(pageComponent, selected) {
		const {schedulingView} = pageComponent.props;
		let eyeOptions = pageComponent.state.eyeOptions.slice();

		applySelectedEyeOption(eyeOptions, selected);

		pageComponent.setState({eyeOptions, recalculateSteps: selected.name === EYE_OPTION_NAME.SHOW_WEEKENDS}, () => {
			const localStorageKey = getEyeOptionStorageKey(pageComponent);
			if (localStorageKey) {
				Util.localStorageSetItem(localStorageKey, JSON.stringify(eyeOptions));
			}

			const resetStateViews = [SCHEDULING_VIEW.CAPACITY_OVERVIEW, SCHEDULING_VIEW.PLACEHOLDERS];
			if (resetStateViews.includes(schedulingView)) {
				if (selected.name === EYE_OPTION_NAME.SHOW_HEATMAP) {
					clearDrawnStepDataArrayMap();
					clearDrawnStepsMap();
				}

				pageComponent.resetState();
			} else {
				pageComponent.redrawCanvasTimeline({preventFiltering: false, clearHasDrawnCaches: true});
			}
		});
	}

	static onFilterChange(pageComponent, filterValues, filterFunctions) {
		const {staffingModeActive, filters, hasRemovedRoleFilter, data, heatmapFiltering} = pageComponent.state;

		if (filterValues && (filters !== undefined || Object.keys(filterValues)?.length > 0)) {
			const {schedulingView} = pageComponent.props;

			const isPeopleScheduling = schedulingView === SCHEDULING_VIEW.PEOPLE;
			const isProjectScheduling = schedulingView === SCHEDULING_VIEW.PROJECTS;
			const isPlaceholdersScheduling = schedulingView === SCHEDULING_VIEW.PLACEHOLDERS;

			const updateData = {filters: filterValues, filterFunctions};

			if (isPlaceholdersScheduling && staffingModeActive && !hasRemovedRoleFilter) {
				const currentlyFilteringRole = filters.person?.role?.length;
				const filteringRoleInChange = filterValues.person?.role?.length;

				if (currentlyFilteringRole && !filteringRoleInChange) {
					trackEvent(getEventTrackingMessage(pageComponent, EVENT_TYPE.STAFFING_ROLE_FILTER_REMOVED), 'Removed');
					updateData.hasRemovedRoleFilter = true;
				}
			}

			if (!staffingModeActive) {
				Util.localStorageSetItem(getFilterStorageKey(pageComponent), JSON.stringify(filterValues));
			}

			if (!staffingModeActive && (!isPeopleScheduling || !isProjectScheduling || heatmapFiltering)) {
				clearPagecomponentHeatmapCache(pageComponent);
			}

			const updatePageComponentState = () => {
				pageComponent.setState(updateData, () => {
					const filterChangeTrackingMessage = getEventTrackingMessage(pageComponent, EVENT_TYPE.ON_FILTER_CHANGE);
					if (filterChangeTrackingMessage) {
						trackEvent(filterChangeTrackingMessage, 'Changed');
					}

					if (heatmapFiltering) {
						pageComponent.timeline.showLoader(() => {
							pageComponent.redrawCanvasTimeline({preventFiltering: false, clearHasDrawnCaches: true});
						});
					} else {
						pageComponent.redrawCanvasTimeline({
							preventFiltering: false,
						});
					}
				});
			};

			if (staffingModeActive) {
				pageComponent.setState({updatingSuggestedPersons: true}, async () => {
					getSuggestedPersonsByFilter(pageComponent, filterValues).then(response => {
						const {suggestedPersons} = response;

						if (suggestedPersons) {
							data.suggestedPersons = suggestedPersons;
						}

						if (staffingModeActive) {
							updateData.updatingSuggestedPersons = false;
						}

						updatePageComponentState();
					});
				});
			} else {
				updatePageComponentState();
			}
		}
	}

	static onGroupByChange(pageComponent, groupBy) {
		if (!Object.values(getGroupByOptions(pageComponent)).includes(groupBy)) {
			return;
		}

		if (groupBy === pageComponent.state.groupBy) {
			return;
		}

		pageComponent.setState({groupBy}, () => {
			localStorage.setItem(getGroupByStorageKey(pageComponent), groupBy);
		});

		clearDrawnStepDataArrayMap();
		clearDrawnStepsMap();

		const {schedulingView} = pageComponent.props;
		const resetStateViews = [SCHEDULING_VIEW.PEOPLE, SCHEDULING_VIEW.PLACEHOLDERS];
		if (resetStateViews.includes(schedulingView)) {
			pageComponent.resetState();
		} else {
			pageComponent.redrawCanvasTimeline({preventFiltering: false});
		}

		trackEvent(getEventTrackingMessage(pageComponent, EVENT_TYPE.ON_GROUP_BY_CHANGE), 'Changed', {groupBy});
	}

	static scroll(pageComponent, value) {
		const {timeline} = pageComponent;
		if (timeline) {
			timeline.scroll(value, {applyEasing: true});
		}
	}

	static onMoveDateButtonClick(pageComponent, moveForward) {
		if (moveForward) {
			this.scroll(pageComponent, 0.33);
		} else {
			this.scroll(pageComponent, -0.33);
		}
	}

	static onScrollToToday(pageComponent) {
		const {timeline} = pageComponent;
		if (timeline) {
			timeline.scrollToToday(0.25);
		}
	}

	static onTimelineHorizontalScroll(pageComponent) {
		const {showExpandedActionMenu, contextMenuOptions} = pageComponent.state;

		const updateData = {};

		if (showExpandedActionMenu) {
			updateData.showExpandedActionMenu = false;
		}

		if (contextMenuOptions) {
			updateData.contextMenuX = null;
			updateData.contextMenuY = null;
			updateData.contextMenuOptions = null;
		}

		if (Object.entries(updateData).length > 0) {
			pageComponent.setState(updateData);
		}
	}

	static onTimelineVerticalScroll(pageComponent, delta) {
		const {showExpandedActionMenu, contextMenuOptions} = pageComponent.state;

		const updateData = {};

		if (showExpandedActionMenu) {
			updateData.showExpandedActionMenu = false;
		}

		if (contextMenuOptions) {
			updateData.contextMenuX = null;
			updateData.contextMenuY = null;
			updateData.contextMenuOptions = null;
		}

		if (Object.entries(updateData).length > 0) {
			pageComponent.setState(updateData);
		}
	}

	static onClick(pageComponent, event) {
		const {showExpandedActionMenu, contextMenuX} = pageComponent.state;

		if ((!event.target || event.target.id !== 'actions-btn') && showExpandedActionMenu) {
			this.hideExpandedActionMenu(pageComponent);
		}

		if (contextMenuX) {
			pageComponent.setState({contextMenuX: undefined, contextMenuY: undefined, contextMenuOptions: undefined});
		}
	}

	static onMouseMove(pageComponent, event) {
		const {timeline} = pageComponent;

		if (timeline) {
			const {showDetailBox, showCanvasTooltip, showDistributionBox} = pageComponent.state;

			// placeholder information icon hover
			displayPlaceholderInformation(pageComponent, event);

			// project or program information
			displayProjectInformation(pageComponent, event);

			if (showDetailBox || showCanvasTooltip) {
				const stateData = {};

				const mouseX = event.clientX;
				const mouseY = event.clientY - timeline.getCanvasOffset().top;

				const mouseTargetData = interactionManager.getDataAtPosition(mouseX, mouseY);
				const targetingItem = mouseTargetData?.itemData?.item;
				const targetingGroup = mouseTargetData?.groupData?.group;

				if (!targetingItem && showDetailBox) {
					stateData.showDetailBox = false;
					stateData.hoverX = null;
					stateData.hoverY = null;
					stateData.detailBoxData = null;
				}

				if (showCanvasTooltip && !targetingItem && !targetingGroup) {
					stateData.showCanvasTooltip = false;
				}

				if (!targetingItem && showDistributionBox) {
					stateData.showDistributionBox = false;
					stateData.hoverX = null;
					stateData.hoverY = null;
					stateData.distributionBoxData = null;
				}

				if (Object.entries(stateData)?.length > 0) {
					pageComponent.setState(stateData);
				}
			}
		}
	}

	static onPersonImageLoad(pageComponent, args = null) {
		const timelineRedrawArgs = args || {preventFiltering: true, isInitialLoad: true, preventHeatmapCalculation: true};

		if (pageComponent.personImageLoadTimeout) {
			clearTimeout(pageComponent.personImageLoadTimeout);
			pageComponent.personImageLoadTimeout = null;
		}

		pageComponent.personImageLoadTimeout = setTimeout(() => {
			pageComponent.redrawCanvasTimeline(timelineRedrawArgs);
		}, 500);
	}

	static onGroupExpansionToggle(pageComponent, group, isExpandAll = false) {
		const {schedulingView, expansionMap} = pageComponent.props;
		const excludedGroups = [GROUP_TYPE.TOTAL_RESOURCE_UTILIZATION];

		if (group && !excludedGroups.includes(group.groupType)) {
			expansionMap.set(group.id, group.expanded);
		}

		if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
			pageComponent.expandLazyLoadGroup(group, isExpandAll);
		}
	}

	static onForegroundContextMenu(pageComponent, event, mouseTargetData, canvasDate) {
		event.preventDefault();

		if (!mouseTargetData.itemData) {
			return;
		}

		const {item} = mouseTargetData.itemData;

		if (!item) {
			return;
		}

		const {staffingModeActive} = pageComponent.state;
		const {placeholderAllocation} = item.data;
		const staffed = staffingModeActive && placeholderAllocation?.personId;

		// hide the tooltip
		pageComponent.setState({showDetailBox: false, showCanvasTooltip: false, hoverX: null, hoverY: null});

		if (item.itemType === ITEM_TYPE.TASK) {
			onTaskContextMenu(pageComponent, event, item);
		}

		if (item.itemType === ITEM_TYPE.PLACEHOLDER_ALLOCATION) {
			onPlaceholderAllocationContextMenu(pageComponent, event, item, canvasDate, staffed);
		}

		if (item.itemType === ITEM_TYPE.PROJECT_ALLOCATION) {
			onAllocationContextMenu(pageComponent, event, item, canvasDate);
		}
	}

	static onExpandActionMenu(pageComponent) {
		const {collapsedActionMenuX, collapsedActionMenuY, collapsedActionMenuData, actionMenuType} = pageComponent.state;

		pageComponent.setState(
			{
				expandedActionMenuX: collapsedActionMenuX,
				expandedActionMenuY: collapsedActionMenuY,
				expandedActionMenuData: collapsedActionMenuData,
				expandedActionMenuType: actionMenuType,
			},
			() => {
				this.setActionMenuOptions(pageComponent);
			}
		);
	}

	static onVisualizationModeChange(pageComponent, visualizationMode) {
		pageComponent.timeline.preventRedraw = true;
		const schedulingOptions = {
			...pageComponent.state.schedulingOptions,
			visualizationMode,
		};
		if (!canUseAllocationControls(visualizationMode)) {
			schedulingOptions.calcWin = false;
			schedulingOptions.hideSoft = false;
			schedulingOptions.hideHard = false;
		}
		updateSuperProperty(SUPER_PROPERTY.AMPLITUDE_PAGE_INFO, pageComponent.superPropertyChecksum, {visualizationMode});
		trackEvent('Heatmap Visualization Mode', 'Changed');
		pageComponent.setState(
			{
				schedulingOptions,
			},
			() => {
				const localStorageKey = getVisualizationModeStorageKey(pageComponent);
				if (localStorageKey) {
					localStorage.setItem(localStorageKey, visualizationMode);
				}

				clearPagecomponentHeatmapCache(pageComponent);
				pageComponent.resetState();
			}
		);
	}

	static onUtilizationFormatChange(pageComponent, utilizationFormat) {
		pageComponent.setState(
			{
				schedulingOptions: {
					...pageComponent.state.schedulingOptions,
					utilizationFormat,
				},
			},
			() => {
				const localStorageKey = getUtilizationFormatStorageKey(pageComponent);
				if (localStorageKey) {
					localStorage.setItem(localStorageKey, utilizationFormat);
				}

				pageComponent.redrawCanvasTimeline({preventFiltering: true, isInitialLoad: false});
			}
		);
	}

	static onSortByChange(pageComponent, sortBy) {
		pageComponent.setState({sortBy}, () => {
			pageComponent.state.groups.forEach(group => group.sort?.(pageComponent));
			const localStorageKey = getSortByStorageKey(pageComponent);
			if (localStorageKey) {
				localStorage.setItem(localStorageKey, sortBy);
			}

			pageComponent.redrawCanvasTimeline({preventFiltering: true, isInitialLoad: false});
			trackEvent('Staffing Mode Sort By', 'Changed', {sortBy});
		});
	}

	static hideExpandedActionMenu = pageComponent => {
		pageComponent.setState({
			showExpandedActionMenu: false,
			expandedActionMenuType: null,
		});
	};

	static hideCollapsedActionMenu = pageComponent => {
		if (pageComponent.state.showCollapsedActionMenu) {
			pageComponent.setState({
				showCollapsedActionMenu: false,
			});
		}
	};

	static setActionMenuOptions(pageComponent) {
		const {data, expandedActionMenuData, actionMenuType, staffingModeActive} = pageComponent.state;
		const {formatMessage} = pageComponent.props.intl;

		const actionMenuOptions = [];
		if (expandedActionMenuData) {
			const {person, project, projectGroup, placeholder} = expandedActionMenuData;

			const findProjectPersons = () => {
				const projectPersonsByPerson = DataManager.getProjectPersonsByPersonId(pageComponent, person.id);

				let projectIds = [];
				if (projectGroup) {
					// If connected project, find the project ids of all connected projects
					projectIds = DataManager.getProjectsByProjectGroupId(pageComponent, projectGroup.id).map(
						project => project.id
					);
				} else {
					projectIds = [project.id];
				}

				return projectPersonsByPerson?.filter(projectPerson => projectIds.includes(projectPerson.projectId));
			};

			switch (actionMenuType) {
				case SCHEDULING_ACTION_MENU_TYPE.PLACEHOLDER:
					const placeholderAllocations = DataManager.getPlaceholderAllocationByPlaceholderId(
						pageComponent,
						placeholder?.id
					);
					const placeholderAllocationIds = placeholderAllocations?.map(allocation => allocation.id) || [];

					// FIND AVAILABLE TEAM MEMBER
					if (
						shouldDisplayActionMenu(
							pageComponent,
							expandedActionMenuData,
							actionMenuType,
							ACTION_MENU_OPTION_TYPE.PLACEHOLDER_FIND_AVAILABLE_TEAM_MEMBER
						)
					) {
						actionMenuOptions.push({
							text: formatMessage({id: 'scheduling.find_available_team_member'}),
							onClick: () => {
								trackEvent('Find Available Team Member From Context Menu', 'Clicked');
								EventManager.hideExpandedActionMenu(pageComponent);
								pageComponent.activateStaffingMode(placeholder.id);
							},
						});
					}

					// ASSIGN TO PERSON
					if (
						shouldDisplayActionMenu(
							pageComponent,
							expandedActionMenuData,
							actionMenuType,
							ACTION_MENU_OPTION_TYPE.PLACEHOLDER_ASSIGN_TO_PERSON
						)
					) {
						actionMenuOptions.push({
							text: formatMessage({id: 'placeholder.assign_to_team_member.action'}),
							onClick: () => {
								trackEvent('Assign To From Placeholder Allocation Context Menu', 'Clicked');
								EventManager.hideExpandedActionMenu(pageComponent);
								handleAssignToPerson(pageComponent, placeholder, placeholderAllocationIds);
							},
						});
					}

					// REPLACE WITH PERSON
					if (
						shouldDisplayActionMenu(
							pageComponent,
							expandedActionMenuData,
							actionMenuType,
							ACTION_MENU_OPTION_TYPE.PLACEHOLDER_REPLACE_WITH_PERSON
						)
					) {
						actionMenuOptions.push({
							text: formatMessage({id: 'scheduling.replace_with_person'}),
							onClick: () => {
								this.hideExpandedActionMenu(pageComponent);

								if (staffingModeActive) {
									const suggestedPersonIds = data.suggestedPersons?.map(personRank => personRank.personId);
									const placeholderSkills = DataManager.getPlaceholderSkillsByPlaceholder(
										pageComponent,
										placeholder.id
									);
									const skillIds = placeholderSkills?.map(placeholderSkill => placeholderSkill.skillId) || [];

									handleReplacePlaceholderWithPerson(
										placeholder.id,
										staffingModeActive,
										placeholder,
										placeholderAllocations,
										skillIds,
										suggestedPersonIds
									);
								} else {
									handleReplacePlaceholderWithPerson(placeholder.id);
								}
							},
						});
					}

					// EDIT PLACEHOLDER
					if (
						shouldDisplayActionMenu(
							pageComponent,
							expandedActionMenuData,
							actionMenuType,
							ACTION_MENU_OPTION_TYPE.PLACEHOLDER_EDIT
						)
					) {
						actionMenuOptions.push({
							text: formatMessage({id: 'placeholder.edit_placeholder'}),
							onClick: () => {
								this.hideExpandedActionMenu(pageComponent);
								showModal({
									type: MODAL_TYPE.NEW_PLACEHOLDER,
									placeholderId: placeholder.id,
									projectId: placeholder.projectGroupId ? null : placeholder.projectId,
									projectGroupId: placeholder.projectGroupId,
									staffingModeActive: pageComponent.state.staffingModeActive,
								});
							},
						});
					}

					// DELETE PLACEHOLDER
					if (
						shouldDisplayActionMenu(
							pageComponent,
							expandedActionMenuData,
							actionMenuType,
							ACTION_MENU_OPTION_TYPE.PLACEHOLDER_DELETE
						)
					) {
						actionMenuOptions.push({
							text: formatMessage({id: 'placeholder.delete_placeholder'}),
							onClick: () => {
								this.hideExpandedActionMenu(pageComponent);
								handleDeletePlaceholder(placeholder.id, placeholderAllocations, formatMessage);
							},
						});
					}

					break;
				case SCHEDULING_ACTION_MENU_TYPE.PERSON:
					if (
						shouldDisplayActionMenu(
							pageComponent,
							expandedActionMenuData,
							actionMenuType,
							ACTION_MENU_OPTION_TYPE.PERSON_ADD_TO_PROJECT
						)
					) {
						actionMenuOptions.push({
							text: formatMessage({id: 'scheduling.add_to_project'}),
							onClick: () => {
								this.hideExpandedActionMenu(pageComponent);
								handleAddPersonToProject(pageComponent, person);
							},
						});
					}

					if (
						shouldDisplayActionMenu(
							pageComponent,
							expandedActionMenuData,
							actionMenuType,
							ACTION_MENU_OPTION_TYPE.PERSON_REMOVE_FROM_PROJECT
						)
					) {
						const option = {
							text: formatMessage({id: 'scheduling.remove_from_project'}),
							onClick: () => {
								this.hideExpandedActionMenu(pageComponent);
								handleRemovePerson(pageComponent, project, projectGroup, person, findProjectPersons());
							},
						};

						if (isProgramRelation(pageComponent, project, person)) {
							option.tooltip = formatMessage(
								{id: 'scheduling.cannot_remove_from_project'},
								{program: ProgramUtil.programText(formatMessage)}
							);
							option.disabled = true;
						}

						actionMenuOptions.push(option);
					}

					break;
				case SCHEDULING_ACTION_MENU_TYPE.PROJECT:
					const projectOrProjectGroup = project || projectGroup;

					if (projectOrProjectGroup) {
						const {companyProjectGroupId, companyProjectId, customProjectId} = projectOrProjectGroup;

						if (
							shouldDisplayActionMenu(
								pageComponent,
								expandedActionMenuData,
								actionMenuType,
								ACTION_MENU_OPTION_TYPE.PROJECT_VIEW_PROJECT
							)
						) {
							actionMenuOptions.push({
								text: formatMessage({
									id: companyProjectGroupId
										? 'overview_projects.group_link'
										: 'scheduling.allocation_modal.view_project',
								}),
								onClick: () => {
									handleViewProject(
										pageComponent,
										companyProjectId,
										customProjectId,
										companyProjectGroupId,
										null
									);
								},
							});
						}

						if (
							shouldDisplayActionMenu(
								pageComponent,
								expandedActionMenuData,
								actionMenuType,
								ACTION_MENU_OPTION_TYPE.PROJECT_REMOVE_FROM_PROJECT
							)
						) {
							const option = {
								text: formatMessage({
									id: 'scheduling.remove_from_project',
								}),
								onClick: () => {
									EventManager.hideExpandedActionMenu(pageComponent);
									handleRemovePerson(pageComponent, project, projectGroup, person, findProjectPersons());
								},
							};

							if (isProgramRelation(pageComponent, project, person)) {
								option.tooltip = formatMessage(
									{id: 'scheduling.cannot_remove_from_project'},
									{program: ProgramUtil.programText(formatMessage)}
								);
								option.disabled = true;
							}

							actionMenuOptions.push(option);
						}

						if (
							shouldDisplayActionMenu(
								pageComponent,
								expandedActionMenuData,
								actionMenuType,
								ACTION_MENU_OPTION_TYPE.PROJECT_SET_STATUS_RUNNING
							)
						) {
							actionMenuOptions.push({
								text: formatMessage({id: 'running_reminder_modal.button_text'}),
								onClick: () => {
									this.hideExpandedActionMenu(pageComponent);
									handleSetProjectStatus(pageComponent, expandedActionMenuData.id, PROJECT_STATUS.RUNNING);
								},
							});
						}

						if (
							shouldDisplayActionMenu(
								pageComponent,
								expandedActionMenuData,
								actionMenuType,
								ACTION_MENU_OPTION_TYPE.PROJECT_DUPLICATE
							) &&
							!expandedActionMenuData.isSageIntacctEnabled
						) {
							actionMenuOptions.push({
								text: formatMessage({id: 'project_settings.duplicate-project'}),
								onClick: () => {
									this.hideExpandedActionMenu(pageComponent);
									showModal({
										type: MODAL_TYPE.NEW_PROJECT_V2,
										projectToDuplicateId: expandedActionMenuData.id,
									});
								},
							});
						}

						if (
							shouldDisplayActionMenu(
								pageComponent,
								expandedActionMenuData,
								actionMenuType,
								ACTION_MENU_OPTION_TYPE.PROJECT_DELETE
							)
						) {
							const {project} = expandedActionMenuData;

							actionMenuOptions.push({
								text: formatMessage({id: 'settings.delete-project'}),
								onClick: () => {
									this.hideExpandedActionMenu(pageComponent);
									handleDeleteProject(pageComponent, project?.id);
								},
							});
						}
					}

					break;
				case SCHEDULING_ACTION_MENU_TYPE.PROGRAM:
					const {prefix} = expandedActionMenuData;

					if (
						shouldDisplayActionMenu(
							pageComponent,
							expandedActionMenuData,
							actionMenuType,
							ACTION_MENU_OPTION_TYPE.PROGRAM_VIEW_PROGRAM
						)
					) {
						actionMenuOptions.push({
							text: formatMessage(
								{id: 'scheduling.view_program'},
								{program: ProgramUtil.programText(formatMessage)}
							),
							onClick: () => {
								handleViewProject(pageComponent, null, null, null, prefix);
							},
						});
					}

					break;
				default:
					break;
			}
		}

		pageComponent.setState({actionMenuOptions, showExpandedActionMenu: actionMenuOptions?.length > 0});
	}

	static onUtilizationHeatmapItemMouseEnter(pageComponent, event, tooltipData) {
		const {timeline} = pageComponent;

		if (timeline) {
			const offsetTop = timeline.getCanvasOffset().top;

			pageComponent.setState({
				canvasTooltipX: event.clientX,
				canvasTooltipY: event.clientY - offsetTop,
				canvasTooltipData: tooltipData,
				showCanvasTooltip: false,
			});

			setTimeout(() => {
				pageComponent.setState({
					showCanvasTooltip: true,
					canvasTooltipX: event.clientX,
					canvasTooltipY: event.clientY - offsetTop,
				});
			}, TOOLTIP_DISPLAY_DELAY_MS);
		}
	}

	static onUtilizationHeatmapItemMouseLeave(pageComponent, event) {
		pageComponent.setState({
			showCanvasTooltip: false,
			canvasTooltipData: null,
			canvasTooltipX: null,
			canvasTooltipY: null,
		});
	}

	static onDemandHeatmapItemMouseEnter(pageComponent, event, group, tooltipData) {
		const {timeline} = pageComponent;

		if (timeline) {
			const {schedulingView} = pageComponent.props;

			if (schedulingView !== SCHEDULING_VIEW.CAPACITY_OVERVIEW || group.groupType === GROUP_TYPE.PERSON) {
				return;
			}

			const offsetTop = timeline.getCanvasOffset().top;

			pageComponent.setState({
				canvasTooltipX: event.clientX,
				canvasTooltipY: event.clientY - offsetTop,
				canvasTooltipData: tooltipData,
				showCanvasTooltip: false,
			});

			setTimeout(() => {
				pageComponent.setState({
					showCanvasTooltip: true,
					canvasTooltipX: event.clientX,
					canvasTooltipY: event.clientY - offsetTop,
				});
			}, TOOLTIP_DISPLAY_DELAY_MS);
		}
	}

	static onDemandHeatmapItemMouseLeave(pageComponent, event) {
		pageComponent.setState({
			showCanvasTooltip: false,
			canvasTooltipData: null,
			canvasTooltipX: null,
			canvasTooltipY: null,
		});
	}

	static onEndGhost(pageComponent, ghostedItem, movedToNewGroup) {
		const {items} = pageComponent.state;
		const placeholderAllocation = ghostedItem.data.placeholderAllocation;

		if (movedToNewGroup) {
			const ghostId = placeholderAllocation.id + SCHEDULE_ITEM_GHOST;
			// If ghost item has been moved to another group, keep the ghost, and add to state.
			const ghostItem = items.find(item => item.data.id === ghostId);
			if (ghostItem) {
				addToChangeList(pageComponent, STAFFING_CHANGE_LIST_ENTITIES.PLACEHOLDER_ALLOCATIONS_GHOSTS, ghostItem);
			}
		} else {
			// If ghost item hasn't been moved to another group, just remove the ghost item.
			cleanUpGhosting(pageComponent, placeholderAllocation);
		}
	}

	static onStartGhost(pageComponent, item, items) {
		const {placeholderAllocation} = item.originalItem.data;
		createGhost(pageComponent, items, placeholderAllocation);
	}

	static onItemCreate(pageComponent, group, startDate, endDate) {
		const {person, placeholder, project, projectGroup, phase, task, projectEntityGroupType, hasItemCreate} = group.data;
		const {isProjectTimeline, sharedContext} = pageComponent.props;
		const {staffingModeActive} = pageComponent.state;

		if (!hasItemCreate) return;

		const momentStartDate = getMomentFromCanvasTimelineDate(startDate);
		const momentEndDate = getMomentFromCanvasTimelineDate(endDate);

		if (placeholder) {
			if (hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE)) {
				showModal({
					type: MODAL_TYPE.PLACEHOLDER_ALLOCATION,
					projectId: placeholder.projectId,
					projectGroupId: placeholder.projectGroupId,
					dragStartDate: momentStartDate,
					dragEndDate: momentEndDate,
					staffingModeActive,
					placeholderInput: placeholder,
				});
			}
		} else if (person) {
			const data = pageComponent.getData();
			const {company} = data;
			const schedulingOptions = pageComponent.state.schedulingOptions;
			const isUsingProjectAllocation = getVisualizationMode(schedulingOptions, company, VISUALIZATION_MODE.ALLOCATION);

			const isCreatingInNonProjectTime = group.groupType === GROUP_TYPE.NON_PROJECT_TIME;
			const hasCreatePermission =
				(!isUsingProjectAllocation && !isCreatingInNonProjectTime) || hasPermission(PERMISSION_TYPE.ALLOCATION_CREATE);
			const projectDoneOrHalted = project && isProjectDoneOrHalted(project.status);

			const canOnlyModifyOwnTimeOff =
				company.allUsersModifyTimeOff &&
				hasFeatureFlag('pto_all_users_can_modify_time_off') &&
				pageComponent.getData().viewer.actualPersonId === person.id &&
				!hasCreatePermission;

			if ((hasCreatePermission || canOnlyModifyOwnTimeOff) && !sharedContext && !projectDoneOrHalted) {
				const {
					projectGroups,
					projects,
					idleTimes,
					projectPersons,
					viewer,
					persons,
					phases,
					holidayCalendars,
					holidayCalendarEntries,
					roles,
					teams,
					teamPersons,
				} = data;

				if (isProjectTimeline) {
					tracking.trackEvent('Create Allocation from Timeline');
					trackComplexEvent('Allocation', 'Created', {location: 'From Timeline'});
				}

				showModal({
					type: MODAL_TYPE.CANVAS_CREATE,
					isCreatingInNonProjectTime,
					projectGroups,
					projects,
					idleTimes,
					projectPersons,
					actualPersonId: viewer.actualPersonId,
					persons,
					selectedPersonId: person.id,
					selectedProject: project || projectGroup || undefined,
					phases,
					preselectTaskTab: projectEntityGroupType === PROJECT_ENTITY_GROUP_TYPE.TASK,
					company,
					holidayCalendars,
					holidayCalendarEntries,
					allocation: {
						personId: person.id,
						startDay: momentStartDate.date(),
						startMonth: momentStartDate.month() + 1,
						startYear: momentStartDate.year(),
						endDay: momentEndDate.date(),
						endMonth: momentEndDate.month() + 1,
						endYear: momentEndDate.year(),
						monday: person.monday,
						tuesday: person.tuesday,
						wednesday: person.wednesday,
						thursday: person.thursday,
						friday: person.friday,
						saturday: person.saturday,
						sunday: person.sunday,
						registerTime: false,
					},
					roles,
					teams,
					teamPersons,
					staffingModeActive,
					schedulingOptions,
					canOnlyModifyOwnTimeOff,
				});
			}
		} else if (project) {
			const {schedulingView} = pageComponent.props;
			const data = pageComponent.getData();
			const {projects, projectPersons, viewer, persons, phases, roles} = data;

			const isPhaseTabSelected = group.groupType === GROUP_TYPE.PHASE || group.id.includes(PROJECT_SUB_GROUP_TYPE.PHASES);

			showModal({
				type: MODAL_TYPE.CANVAS_CREATE_NEW_MODAL,
				selectedProjectId: project.id,
				selectedPhaseId: phase?.id || task?.phaseId,
				isPhaseTabSelected,
				estimationUnit: project.estimationUnit,
				roles,
				persons,
				projects,
				phases,
				projectPersons,
				startDate: momentStartDate,
				endDate: momentEndDate,
				isProjectScheduling: schedulingView === SCHEDULING_VIEW.PROJECTS,
				viewerId: viewer.actualPersonId,
			});
		}
	}

	static updateFreeDragData(item, dragData) {
		const {pageComponent} = item;

		filterFreeDragCreatedGroups(pageComponent, item, dragData);

		// do not update if there is no group under mouse
		if (!dragData.groupData || !dragData.mouseDown) {
			return;
		}

		const {group} = dragData.groupData;
		const {initialGroup} = dragData;

		if (group.isInCollapsableSection) {
			dragData.destinationGroup = initialGroup;
			dragData.innermostVisibleParentGroup = null;
			return;
		}

		const outerParentGroup = findFreeDragOuterParentGroup(pageComponent, group, item, dragData);
		if (item.isFreeDragOuterGroup(outerParentGroup)) {
			if (isHoveringGhostOriginGroup(pageComponent, outerParentGroup)) {
				setDestinationGroup(dragData, outerParentGroup);

				if (hasFeatureFlag('improving_heatmap_frontend_performance') && DataManager.hasTemporaryVisibleItem()) {
					DataManager.clearTemporaryVisibleItemsMap();

					item.getHeatmapGroupIds()?.forEach(groupId => {
						recalculateGroupHeatmapCache(pageComponent, groupId);
					});
				}

				updateItemGroup(pageComponent, item, outerParentGroup);
			} else {
				const allocationGroup = getAllocationGroup(pageComponent, item, outerParentGroup);

				if (allocationGroup && item.isFreeDragDestinationGroup(allocationGroup)) {
					const {itemGroup, heatmapGroup} = getItemGroupsFromDragDestination(
						pageComponent,
						item,
						allocationGroup,
						outerParentGroup
					);
					setDestinationGroup(dragData, outerParentGroup, allocationGroup, itemGroup);
					updateItemGroup(pageComponent, item, heatmapGroup, itemGroup);
				} else {
					// not assigned to project
					setDestinationGroup(dragData, outerParentGroup);
				}
			}
		}
	}

	static onDeletePlaceholderOnSaveChange(pageComponent) {
		pageComponent.setState({
			deletePlaceholderOnSave: !pageComponent.state.deletePlaceholderOnSave,
		});
	}

	static onTotalResourceUtilizationGroupExpand(pageComponent, expanded) {
		const {groups} = pageComponent.state;

		if (groups) {
			for (const group of groups) {
				if (group.groupType === GROUP_TYPE.PERSON_GROUPING_GROUP) {
					group.setExpanded(expanded);
					this.onGroupExpansionToggle(pageComponent, group);
				}
			}
		}
	}

	static onDoneOrHaltedMoveAttempt(pageComponent) {
		const {intl} = pageComponent.props;
		const {formatMessage} = intl;

		showModal({
			type: MODAL_TYPE.GENERIC,
			content: (
				<div>
					<Warning messageId="scheduling.done_warning" useInfoIcon={true} />
					<div className="warning-part-2">{formatMessage({id: 'scheduling.done_warning_2'})}</div>
				</div>
			),
			className: 'default-warning-modal',
			buttons: [
				{
					text: formatMessage({id: 'common.filter-close'}),
					style: BUTTON_STYLE.FILLED,
					color: BUTTON_COLOR.WHITE,
				},
			],
		});
	}

	static onDependencyMouseEnterOrLeave(pageComponent, item) {
		const {schedulingView} = pageComponent.props;

		if (schedulingView === SCHEDULING_VIEW.PROJECTS) {
			const {task} = item.data;

			if (isTaskInHierarchy(pageComponent, task)) {
				pageComponent.redrawCanvasTimeline({preventFiltering: true});
			}

			const relevantDependencyTaskIdSet = getRelevantDependencyTaskIdSet(pageComponent);
			if (relevantDependencyTaskIdSet?.has(task.id)) {
				pageComponent.redrawCanvasTimeline({preventFiltering: true});
			}
		}
	}

	static async onDistributionItemClick(pageComponent, item, parentId, childId) {
		const enabledClick = false;
		if (enabledClick) {
			const {isUsingNewLazyLoad} = pageComponent;
			const {groups} = pageComponent.state;
			let dataLoaded = pageComponent.state.dataLoaded;

			if (isUsingNewLazyLoad && item.itemType === ITEM_TYPE.PROJECT_SCHEDULING_PROJECT) {
				const projectId = item.data.groupId;
				const {project} = item.data;

				const groupingGroup = project?.isInProgram
					? groups.find(group => group.data.program?.prefix === project.programPrefix)
					: project?.isInProjectGroup
					? groups.find(group => group.id === project.projectGroupId)
					: null;

				const projectGroupPredicate = group => group.data.id === projectId;
				const projectGroup = groupingGroup
					? groupingGroup.groups.find(projectGroupPredicate)
					: groups.find(projectGroupPredicate);

				if (!projectGroup.fullDataLoaded) {
					pageComponent.setState({showLoader: true});
					await pageComponent.fetchProjectData(pageComponent.props, projectGroup.data.companyProjectId);
					pageComponent.setState({showLoader: false});
				}

				dataLoaded = true;
			}

			if (dataLoaded) {
				handleDisplayProjectPhaseCapacity(pageComponent, item, parentId, childId);

				pageComponent.setState({
					clickedItem: null,
					showLoader: false,
				});
			} else if (!isUsingNewLazyLoad) {
				pageComponent.setState({
					showLoader: true,
					clickedItem: {
						itemType: item.itemType,
						parentId,
						childId,
						item,
					},
				});
			}
		}
	}

	static onDistributionItemMoving(
		pageComponent,
		item,
		distributionType,
		movedItem,
		group,
		startDifference,
		endDifference,
		dragData,
		movedDays
	) {
		const {sharedContext} = pageComponent.props;

		if (!sharedContext) {
			const distributionOption = DISTRIBUTION_TYPE[distributionType];

			if (distributionOption !== undefined) {
				const {company} = pageComponent.getData();
				const isUsingProjectAllocation = getVisualizationMode(
					pageComponent.state.schedulingOptions,
					company,
					VISUALIZATION_MODE.ALLOCATION
				);
				const {phase, project} = item.data;
				const hasVisibleItemsTree = hasFeatureFlag('improving_heatmap_frontend_performance');

				let itemsToMove = [];
				if (distributionOption === DISTRIBUTION_TYPE.PROJECT) {
					itemsToMove = pageComponent.getProjectItems(item);
				} else if (distributionOption === DISTRIBUTION_TYPE.PHASE) {
					itemsToMove = getPhaseItems(pageComponent, item);
				}

				if (dragData.dragPoint === ITEM_DRAG_POINT.CENTER) {
					const {timeline} = pageComponent;
					const hideWeekend = timeline.isHideWeekendsSelected();

					for (const itemToMove of itemsToMove) {
						const {startDifference, endDifference} = moveItem(itemToMove, movedDays, hideWeekend);

						itemToMove.startDate += startDifference;
						itemToMove.endDate += endDifference;

						if (isTaskItem(itemToMove)) {
							pageComponent.updateTaskDataDates(
								itemToMove,
								itemToMove.startDate,
								itemToMove.endDate,
								startDifference,
								endDifference
							);
						} else if (isProjectAllocationItem(itemToMove)) {
							const {allocation} = itemToMove.data;
							recalculateGroupHeatmapCache(
								pageComponent,
								IDManager.getPersonGroupId(
									pageComponent,
									allocation.personId,
									allocation.projectId || allocation.projectGroupId
								),
								getRecalculationInterval(itemToMove, startDifference, endDifference)
							);
						}

						if (hasVisibleItemsTree && !isUsingProjectAllocation) {
							DataManager.moveItemTemporarily(pageComponent, itemToMove);
						}
					}
				} else {
					const {startDate: distributionItemStart, endDate: distributionItemEnd} = item;

					for (const itemToMove of itemsToMove) {
						if (isTaskItem(itemToMove)) {
							const {task} = itemToMove.data;

							const isPartOfProject = project && task.projectId === project.id;
							const isPartOfPhase = phase && task.phaseId === phase.id;

							if (isPartOfPhase || isPartOfProject) {
								const taskStartIsDependent =
									DATE_FROM_PRIORITY[task.startFrom] >= DATE_FROM_PRIORITY[distributionOption];
								const taskEndIsDependent =
									DATE_FROM_PRIORITY[task.deadlineFrom] >= DATE_FROM_PRIORITY[distributionOption];

								if (taskStartIsDependent) {
									const newStartDate = distributionItemStart + startDifference;
									itemToMove.startDate = newStartDate;
									pageComponent.updateTaskDataDates(
										itemToMove,
										newStartDate,
										null,
										startDifference,
										endDifference
									);
								}

								if (taskEndIsDependent) {
									const newEndDate = distributionItemEnd + endDifference;
									itemToMove.endDate = newEndDate;
									pageComponent.updateTaskDataDates(
										itemToMove,
										null,
										newEndDate,
										startDifference,
										endDifference
									);
								}

								if (
									(taskStartIsDependent || taskEndIsDependent) &&
									hasVisibleItemsTree &&
									!isUsingProjectAllocation
								) {
									DataManager.moveItemTemporarily(pageComponent, itemToMove);
								}
							}
						} else if (isPhaseItem(itemToMove)) {
							const {phase} = itemToMove.data;
							const phaseStartIsDependent =
								DATE_FROM_PRIORITY[phase.startFrom] >= DATE_FROM_PRIORITY[distributionOption];
							const phaseEndIsDependent =
								DATE_FROM_PRIORITY[phase.deadlineFrom] >= DATE_FROM_PRIORITY[distributionOption];
							if (phaseStartIsDependent) {
								itemToMove.startDate += startDifference;
							}
							if (phaseEndIsDependent) {
								itemToMove.endDate += endDifference;
							}
						}
					}
				}

				return true;
			}
		}

		return false;
	}

	static onDistributionItemMoveEnd(pageComponent, item, distributionType, group, initialGroup, dragData) {
		const {sharedContext} = pageComponent.props;

		if (!sharedContext) {
			const distributionOption = DISTRIBUTION_TYPE[distributionType];

			if (distributionOption !== undefined) {
				const {dataFetched, groups} = pageComponent.state;
				const {company} = pageComponent.getData();
				const isUsingProjectAllocation = getVisualizationMode(
					pageComponent.state.schedulingOptions,
					company,
					VISUALIZATION_MODE.ALLOCATION
				);

				const isPhaseDistribution = distributionOption === DISTRIBUTION_TYPE.PHASE;
				const isProjectDistribution = distributionOption === DISTRIBUTION_TYPE.PROJECT;
				const isProgramDistribution = distributionOption === DISTRIBUTION_TYPE.PROGRAM;

				if (dataFetched || isPhaseDistribution) {
					const {simulationMode} = pageComponent.state;
					const {intl} = pageComponent.props;
					const {formatMessage} = intl;

					const commitMutation = () => {
						const {timeline} = pageComponent;

						const startDate = getMomentFromCanvasTimelineDate(item.startDate);
						const endDate = getMomentFromCanvasTimelineDate(item.endDate);

						let mutationInput = {isWeekendHidden: timeline.isHideWeekendsSelected()};
						if (isPhaseDistribution) {
							const {phase, projectId} = item.data;

							phase.startYear = startDate.year();
							phase.startMonth = startDate.month() + 1;
							phase.startDay = startDate.date();
							phase.deadlineYear = endDate.year();
							phase.deadlineMonth = endDate.month() + 1;
							phase.deadlineDay = endDate.date();

							mutationInput = {
								...mutationInput,
								id: phase.id,
								deadlineDay: endDate.date(),
								deadlineMonth: endDate.month() + 1,
								deadlineYear: endDate.year(),
								startDay: startDate.date(),
								startMonth: startDate.month() + 1,
								startYear: startDate.year(),
								projectId,
							};
						} else if (isProjectDistribution) {
							const {project} = item.data;

							project.projectStartYear = startDate.year();
							project.projectStartMonth = startDate.month() + 1;
							project.projectStartDay = startDate.date();
							project.projectEndYear = endDate.year();
							project.projectEndMonth = endDate.month() + 1;
							project.projectEndDay = endDate.date();

							const projectPhaseIds = DataManager.getPhaseIdsByProjectId(pageComponent, project.id);
							for (const phaseId of projectPhaseIds) {
								const phase = DataManager.getPhaseById(pageComponent, phaseId);

								if (phase) {
									phase.startYear = startDate.year();
									phase.startMonth = startDate.month() + 1;
									phase.startDay = startDate.date();
									phase.deadlineYear = endDate.year();
									phase.deadlineMonth = endDate.month() + 1;
									phase.deadlineDay = endDate.date();
								}
							}

							mutationInput = {
								...mutationInput,
								id: project.id,
								projectEndDay: endDate.date(),
								projectEndMonth: endDate.month() + 1,
								projectEndYear: endDate.year(),
								projectStartDay: startDate.date(),
								projectStartMonth: startDate.month() + 1,
								projectStartYear: startDate.year(),
							};
						}

						if (!simulationMode) {
							const mutationClass = isPhaseDistribution
								? MovePhaseOnTimelineMutation
								: MoveProjectOnTimelineMutation;
							Util.CommitMutation(mutationClass, mutationInput, res => {
								handleMutationSuccess(res);
								if (hasFeatureFlag('improving_heatmap_frontend_performance') && !isUsingProjectAllocation) {
									const itemsToMove = isPhaseDistribution
										? getPhaseItems(pageComponent, item)
										: pageComponent.getProjectItems(item);

									for (const itemToMove of itemsToMove) {
										if (isTaskItem(itemToMove) || isProjectAllocationItem(itemToMove)) {
											const group = DataManager.findHeatmapGroupById(itemToMove.groupId, groups);
											DataManager.updateVisibleItemKey(pageComponent, group, itemToMove);
										}
									}
								}
							});
						} else {
							pageComponent.updateSimulationChangeMap(isPhaseDistribution ? 'phases' : 'projects', item);
						}
					};

					const defaultCallback = () => {
						const movedDays = -dragData.dayDifference;
						pageComponent.moveItem(item, movedDays);
						item.onMoving(item, group, movedDays, movedDays, dragData, movedDays);
						pageComponent.redrawCanvasTimeline({preventFiltering: true});
					};

					if (isProgramDistribution) {
						const {programs} = pageComponent.getData();
						const program = programs.find(program => program.prefix === item.data.program.prefix);

						if (program) {
							const startDate = getMomentFromCanvasTimelineDate(item.startDate);
							const endDate = getMomentFromCanvasTimelineDate(item.endDate);

							program.startDate = startDate;
							program.endDate = endDate;

							Util.CommitMutation(update_program_dates_mutation, {
								id: program.id,
								startDate: startDate.format(DATE_FORMAT_DAY),
								endDate: endDate.format(DATE_FORMAT_DAY),
							});
						}
					} else {
						const isCenterDrag = dragData.dragPoint === ITEM_DRAG_POINT.CENTER;

						if (isCenterDrag && !simulationMode && (!isPhaseDistribution || pageComponent.hasTask(group))) {
							let warningMessage = '';
							if (isPhaseDistribution) {
								warningMessage = formatMessage({id: 'scheduling.warning_move_phase'});
							} else if (isProjectDistribution) {
								warningMessage = isUsingProjectAllocation
									? formatMessage({id: 'scheduling.warning_move_allocation_project'})
									: formatMessage({id: 'scheduling.warning_move_project'});
							}

							handleDisplayDefaultWarning(pageComponent, warningMessage, defaultCallback, commitMutation);
						} else {
							commitMutation();
						}
					}

					pageComponent.setState({
						movedItem: null,
						showLoader: false,
					});
				} else {
					pageComponent.setState({
						showLoader: true,
						movedItem: {
							item,
							group,
							initialGroup,
							dragData,
						},
					});
				}
			}
		}
	}
}
