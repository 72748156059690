/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type RevenueCostProfitCharts_viewer$ref = any;
export type AggregateLevel = "DAY" | "MONTH" | "YEAR" | "%future added value";
export type GLOBAL_FILTER_CONTEXT = "INVOICES" | "LABELS" | "PEOPLE" | "PLACEHOLDERS" | "PROJECTS" | "RESOURCES" | "SKILLS" | "TASKS" | "TIME_REGS" | "%future added value";
export type GLOBAL_FILTER_FIELD = "APPROVAL_STATUS" | "ASSIGNED" | "ASSIGNED_SELF" | "BILLABLE" | "BILLABLE_TIME_REG" | "BILL_FROM" | "BILL_TO" | "BLOCKED" | "BUG" | "CLIENT" | "CLIENT_GUEST_USERS" | "CLIENT_TIME_REG" | "COMPANY_TASK_ID" | "CONTACT" | "CREATED_AT" | "DEADLINE" | "DEPARTMENT" | "DEPARTMENT_TIME_REG" | "DEPENDENCIES" | "DESCRIPTION" | "END_DATE" | "END_DATE_PROJECT" | "END_DATE_TIME_REG" | "FILTER_BY_TIME_REG_SETTINGS" | "FOLLOWER" | "FORECAST" | "HIGH_PRIORITY" | "INTERNAL_TIME" | "INTERNAL_TIME_REG" | "INVOICED" | "LABELS" | "LABEL_TIME_REG" | "NAME" | "NON_CLIENT_TIME_REG" | "NON_DEPARTMENT_TIME_REG" | "NON_PHASE_TIME_REG" | "NON_PROJECT_TIME_REG" | "NON_ROLE_TIME_REG" | "NON_SPRINT_TIME_REG" | "NON_TASK_TIME_REG" | "NO_CONTACT" | "OWNER" | "PERMISSION_LEVEL" | "PERSON" | "PERSON_TIME_REG" | "PHASE_CATEGORY" | "PHASE_TIME_REG" | "PRIORITY_LEVEL" | "PROGRAM" | "PROJECT" | "PROJECT_BUDGET_TYPE" | "PROJECT_DEFAULT_PERIOD_BUDGET_TYPE" | "PROJECT_FOLLOWER" | "PROJECT_GROUP" | "PROJECT_GROUP_SPRINT" | "PROJECT_GROUP_STATUS" | "PROJECT_LABELS" | "PROJECT_MANUAL_PROGRESS" | "PROJECT_OWNER" | "PROJECT_PERSON" | "PROJECT_PHASE" | "PROJECT_SPRINT" | "PROJECT_STAGE" | "PROJECT_STATUS" | "PROJECT_TIME_REG" | "RATE_CARD" | "RECENT_ACTIVITY" | "RECENT_TASK_ACTIVITY" | "RECENT_TIME" | "REMAINING" | "RETAINER_PERIOD" | "ROLE" | "ROLE_TIME_REG" | "SET_DONE_AT" | "SKILL" | "SPRINT_CATEGORY" | "SPRINT_TIME_REG" | "STARRED" | "START_DATE" | "START_DATE_PROJECT" | "START_DATE_TIME_REG" | "STATUS" | "SUB_TASKS" | "TASK" | "TASK_APPROVED" | "TASK_HIERARCHY" | "TASK_LEVEL" | "TASK_TIME_REG" | "TEAM" | "TEAM_MEMBER" | "TEAM_TIME_REG" | "TIME_OFF" | "TIME_OFF_TIME_REG" | "TIME_REGS" | "UPDATED_AT" | "WITH_TASKS" | "WITH_TIME_REG" | "%future added value";
export type GLOBAL_FILTER_LOGICAL_OPERATOR = "AND" | "OR" | "%future added value";
export type GLOBAL_FILTER_OPERATOR = "ALL_IN" | "CONTAINS" | "CONTAINS_NOT" | "GREATER" | "GREATER_OR_EQUAL" | "IN" | "IS" | "IS_ALL" | "IS_NOT" | "IS_NOT_ALL" | "LESS" | "LESS_OR_EQUAL" | "NOT_ALL_IN" | "NOT_IN" | "%future added value";
export type TaskSearchQueryType = {|
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>
|};
export type GlobalSearchFilterType = {|
  field?: ?GLOBAL_FILTER_FIELD,
  operator?: ?GLOBAL_FILTER_OPERATOR,
  value?: ?$ReadOnlyArray<?string>,
  context?: ?GLOBAL_FILTER_CONTEXT,
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>,
  logicalOperator?: ?GLOBAL_FILTER_LOGICAL_OPERATOR,
|};
export type RevenueCostProfitCharts_QueryVariables = {|
  projectId?: ?string,
  startYear: number,
  startMonth: number,
  startDay: number,
  endYear: number,
  endMonth: number,
  endDay: number,
  searchQuery?: ?TaskSearchQueryType,
  aggregateLevel: AggregateLevel,
|};
export type RevenueCostProfitCharts_QueryResponse = {|
  +viewer: ?{|
    +id: string,
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: RevenueCostProfitCharts_viewer$ref,
  |}
|};
export type RevenueCostProfitCharts_Query = {|
  variables: RevenueCostProfitCharts_QueryVariables,
  response: RevenueCostProfitCharts_QueryResponse,
|};
*/


/*
query RevenueCostProfitCharts_Query(
  $projectId: ID
  $startYear: Int!
  $startMonth: Int!
  $startDay: Int!
  $endYear: Int!
  $endMonth: Int!
  $endDay: Int!
  $searchQuery: TaskSearchQueryType
  $aggregateLevel: AggregateLevel!
) {
  viewer {
    id
    actualPersonId
    component(name: "project_budget_v3_revenue_cost_profit_charts")
    ...RevenueCostProfitCharts_viewer_vD8ov
  }
}

fragment RevenueCostProfitCharts_viewer_vD8ov on Viewer {
  company {
    tier
    currency
    id
  }
  project(internalId: $projectId) {
    projectFirstDateYear
    projectFirstDateMonth
    projectFirstDateDay
    projectLastDateYear
    projectLastDateMonth
    projectLastDateDay
    rateCard {
      currency
      id
    }
    estimationUnit
    budget
    budgetType
    billable
    defaultPeriodBudgetType
    aggregatedFinancialNumbers(startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, aggregates: [billableActualTimeAndExpenses, billablePlannedTimeAndExpenses, billableTotalTimeAndExpensesAtCompletion, baselineRevenue, baselineTimeAndExpenses, actualCost, plannedCost, totalCostAtCompletion, baselineCost, actualProfit, plannedProfit, totalProfitAtCompletion, baselineProfit, totalRevenueRecognition, recognitionProfit], aggregateLevel: $aggregateLevel, addAccumulatedNumbers: true, searchQuery: $searchQuery, convertToProjectCurrency: true)
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId",
    "type": "ID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startYear",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startMonth",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "startDay",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endYear",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endMonth",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endDay",
    "type": "Int!"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchQuery",
    "type": "TaskSearchQueryType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "aggregateLevel",
    "type": "AggregateLevel!"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "project_budget_v3_revenue_cost_profit_charts"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"project_budget_v3_revenue_cost_profit_charts\")"
},
v4 = {
  "kind": "Variable",
  "name": "aggregateLevel",
  "variableName": "aggregateLevel"
},
v5 = {
  "kind": "Variable",
  "name": "endDay",
  "variableName": "endDay"
},
v6 = {
  "kind": "Variable",
  "name": "endMonth",
  "variableName": "endMonth"
},
v7 = {
  "kind": "Variable",
  "name": "endYear",
  "variableName": "endYear"
},
v8 = {
  "kind": "Variable",
  "name": "searchQuery",
  "variableName": "searchQuery"
},
v9 = {
  "kind": "Variable",
  "name": "startDay",
  "variableName": "startDay"
},
v10 = {
  "kind": "Variable",
  "name": "startMonth",
  "variableName": "startMonth"
},
v11 = {
  "kind": "Variable",
  "name": "startYear",
  "variableName": "startYear"
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RevenueCostProfitCharts_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "Variable",
                "name": "projectId",
                "variableName": "projectId"
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "RevenueCostProfitCharts_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RevenueCostProfitCharts_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tier",
                "storageKey": null
              },
              (v12/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "internalId",
                "variableName": "projectId"
              }
            ],
            "concreteType": "ProjectType",
            "kind": "LinkedField",
            "name": "project",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectFirstDateYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectFirstDateMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectFirstDateDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectLastDateYear",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectLastDateMonth",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "projectLastDateDay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RateCard",
                "kind": "LinkedField",
                "name": "rateCard",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "estimationUnit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budget",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "budgetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "billable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultPeriodBudgetType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "addAccumulatedNumbers",
                    "value": true
                  },
                  (v4/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "aggregates",
                    "value": [
                      "billableActualTimeAndExpenses",
                      "billablePlannedTimeAndExpenses",
                      "billableTotalTimeAndExpensesAtCompletion",
                      "baselineRevenue",
                      "baselineTimeAndExpenses",
                      "actualCost",
                      "plannedCost",
                      "totalCostAtCompletion",
                      "baselineCost",
                      "actualProfit",
                      "plannedProfit",
                      "totalProfitAtCompletion",
                      "baselineProfit",
                      "totalRevenueRecognition",
                      "recognitionProfit"
                    ]
                  },
                  {
                    "kind": "Literal",
                    "name": "convertToProjectCurrency",
                    "value": true
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "kind": "ScalarField",
                "name": "aggregatedFinancialNumbers",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "RevenueCostProfitCharts_Query",
    "operationKind": "query",
    "text": "query RevenueCostProfitCharts_Query(\n  $projectId: ID\n  $startYear: Int!\n  $startMonth: Int!\n  $startDay: Int!\n  $endYear: Int!\n  $endMonth: Int!\n  $endDay: Int!\n  $searchQuery: TaskSearchQueryType\n  $aggregateLevel: AggregateLevel!\n) {\n  viewer {\n    id\n    actualPersonId\n    component(name: \"project_budget_v3_revenue_cost_profit_charts\")\n    ...RevenueCostProfitCharts_viewer_vD8ov\n  }\n}\n\nfragment RevenueCostProfitCharts_viewer_vD8ov on Viewer {\n  company {\n    tier\n    currency\n    id\n  }\n  project(internalId: $projectId) {\n    projectFirstDateYear\n    projectFirstDateMonth\n    projectFirstDateDay\n    projectLastDateYear\n    projectLastDateMonth\n    projectLastDateDay\n    rateCard {\n      currency\n      id\n    }\n    estimationUnit\n    budget\n    budgetType\n    billable\n    defaultPeriodBudgetType\n    aggregatedFinancialNumbers(startYear: $startYear, startMonth: $startMonth, startDay: $startDay, endYear: $endYear, endMonth: $endMonth, endDay: $endDay, aggregates: [billableActualTimeAndExpenses, billablePlannedTimeAndExpenses, billableTotalTimeAndExpensesAtCompletion, baselineRevenue, baselineTimeAndExpenses, actualCost, plannedCost, totalCostAtCompletion, baselineCost, actualProfit, plannedProfit, totalProfitAtCompletion, baselineProfit, totalRevenueRecognition, recognitionProfit], aggregateLevel: $aggregateLevel, addAccumulatedNumbers: true, searchQuery: $searchQuery, convertToProjectCurrency: true)\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '70d08d435c954fa03e6bb4adf32fd29c';

module.exports = node;
