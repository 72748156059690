import React, {Component} from 'react';
import DropdownV2 from '../../forecast-app/shared/components/dropdowns/dropdown';
import PropTypes from 'prop-types';
import {createFragmentContainer, graphql} from 'react-relay';

class DuplicateProjectDropdown extends Component {
	onChange(selectedProject) {
		if (selectedProject === null) {
			this.props.handleDuplicateProjectSelectChange(null);
		} else {
			const projectToDuplicate = this.props.viewer.projects.edges.find(el => el.node.id === selectedProject.value);
			const duplicatedProjectNode = projectToDuplicate && projectToDuplicate.node;
			this.props.handleDuplicateProjectSelectChange(duplicatedProjectNode);
		}
	}

	render() {
		const duplicateOptions = this.props.viewer.projects.edges
			.filter(project => project.node.fullAccessToProject && !project.node.sageProject.sageProjectId)
			.map(project => {
				return {value: project.node.id, label: project.node.customProjectId + ' ' + project.node.name};
			});

		return (
			<DropdownV2
				options={duplicateOptions}
				onChange={this.onChange.bind(this)}
				value={this.props.projectToDuplicate && this.props.projectToDuplicate.id}
				label={this.props.label}
				placeholder={this.props.placeholder}
				clearable={true}
				required={true}
				invalidInput={this.props.invalidInput}
				errorMessage={this.props.errorMessage}
				customHeight={30}
				useInputHeight={true}
				inputCy={'projects-list'}
				listDataCy={'project'}
			/>
		);
	}
}

DuplicateProjectDropdown.propTypes = {
	handleDuplicateProjectSelectChange: PropTypes.any,
	projectToDuplicate: PropTypes.any,
	label: PropTypes.any,
	placeholder: PropTypes.any,
	invalidInput: PropTypes.bool,
	errorMessage: PropTypes.string,
};

const DuplicateProjectDropdownQuery = graphql`
	query DuplicateProjectDropdown_Query {
		viewer {
			actualPersonId
			component(name: "duplicate_project_dropdown")
			...DuplicateProjectDropdown_viewer
		}
	}
`;

export {DuplicateProjectDropdownQuery};

export default createFragmentContainer(DuplicateProjectDropdown, {
	viewer: graphql`
		fragment DuplicateProjectDropdown_viewer on Viewer {
			id
			projects(first: 10000) {
				edges {
					node {
						id
						name
						companyProjectId
						customProjectId
						useManualAllocations
						useBaseline
						fullAccessToProject
						sageProject {
							sageProjectId
						}
					}
				}
			}
		}
	`,
});
