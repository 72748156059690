/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineCost",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineProfit",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineMinutes",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineRevenue",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineTimeAndExpenses",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baselineMargin",
        "storageKey": null
    }, v9 = [
        (v1 /*: any*/)
    ], v10 = {
        "alias": null,
        "args": null,
        "concreteType": "FinancialNumbers",
        "kind": "LinkedField",
        "name": "financialNumbers",
        "plural": false,
        "selections": [
            (v5 /*: any*/),
            (v7 /*: any*/),
            (v6 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/),
            (v8 /*: any*/)
        ],
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "projectId",
                "type": "String"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "BaselineEditPage_viewer",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/),
                    (v2 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "id",
                        "variableName": "projectId"
                    }
                ],
                "concreteType": "ProjectType",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useBaseline",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useDeliverables",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "budgetType",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "budget",
                        "storageKey": null
                    },
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyProjectId",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customProjectId",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectColor",
                        "storageKey": null
                    },
                    (v1 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineEstimationMinutesPerDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectStartYear",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectStartMonth",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectStartDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectEndYear",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectEndMonth",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "projectEndDay",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineTargetMinutes",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineTarget",
                        "storageKey": null
                    },
                    (v3 /*: any*/),
                    (v4 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineAdjustPercentage",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineAdjustPrice",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "baselineTargetPrice",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useFixedPriceForBaselineRevenue",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "RateCard",
                        "kind": "LinkedField",
                        "name": "rateCard",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            (v2 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "disabledRoles",
                                "plural": true,
                                "selections": [
                                    (v0 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "convertToProjectCurrency",
                                "value": true
                            },
                            {
                                "kind": "Literal",
                                "name": "groupBy",
                                "value": [
                                    "PHASE_BASELINE_ROLE",
                                    "PHASE_BASELINE_EXPENSE",
                                    "PHASE"
                                ]
                            }
                        ],
                        "concreteType": "FinancialNumbers",
                        "kind": "LinkedField",
                        "name": "groupedFinancialNumbers",
                        "plural": true,
                        "selections": [
                            (v5 /*: any*/),
                            (v6 /*: any*/),
                            (v7 /*: any*/),
                            (v3 /*: any*/),
                            (v4 /*: any*/),
                            (v8 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "baselineRatePerHour",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "baselineCostPerHour",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "phaseBaselineRoleId",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "phaseBaselineExpenseId",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "phaseId",
                                "storageKey": null
                            }
                        ],
                        "storageKey": "groupedFinancialNumbers(convertToProjectCurrency:true,groupBy:[\"PHASE_BASELINE_ROLE\",\"PHASE_BASELINE_EXPENSE\",\"PHASE\"])"
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PhaseTypeConnection",
                        "kind": "LinkedField",
                        "name": "phases",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "PhaseTypeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "PhaseType",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "startYear",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "startMonth",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "startDay",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "deadlineDay",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "deadlineMonth",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "deadlineYear",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "PhaseDeliverableTypeConnection",
                                                "kind": "LinkedField",
                                                "name": "phaseDeliverables",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "PhaseDeliverableTypeEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "concreteType": "PhaseDeliverable",
                                                                "kind": "LinkedField",
                                                                "name": "node",
                                                                "plural": false,
                                                                "selections": [
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "DeliverableType",
                                                                        "kind": "LinkedField",
                                                                        "name": "deliverable",
                                                                        "plural": false,
                                                                        "selections": (v9 /*: any*/),
                                                                        "storageKey": null
                                                                    },
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "PhaseBaselineExpenseTypeConnection",
                                                                        "kind": "LinkedField",
                                                                        "name": "phaseBaselineExpenses",
                                                                        "plural": false,
                                                                        "selections": [
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "concreteType": "PhaseBaselineExpenseTypeEdge",
                                                                                "kind": "LinkedField",
                                                                                "name": "edges",
                                                                                "plural": true,
                                                                                "selections": [
                                                                                    {
                                                                                        "alias": null,
                                                                                        "args": null,
                                                                                        "concreteType": "PhaseBaselineExpenseType",
                                                                                        "kind": "LinkedField",
                                                                                        "name": "node",
                                                                                        "plural": false,
                                                                                        "selections": [
                                                                                            {
                                                                                                "alias": null,
                                                                                                "args": null,
                                                                                                "kind": "ScalarField",
                                                                                                "name": "expenseName",
                                                                                                "storageKey": null
                                                                                            },
                                                                                            {
                                                                                                "alias": null,
                                                                                                "args": null,
                                                                                                "concreteType": "ExpenseCategory",
                                                                                                "kind": "LinkedField",
                                                                                                "name": "expenseCategory",
                                                                                                "plural": false,
                                                                                                "selections": (v9 /*: any*/),
                                                                                                "storageKey": null
                                                                                            },
                                                                                            {
                                                                                                "alias": null,
                                                                                                "args": null,
                                                                                                "kind": "ScalarField",
                                                                                                "name": "expenseRevenue",
                                                                                                "storageKey": null
                                                                                            },
                                                                                            {
                                                                                                "alias": null,
                                                                                                "args": null,
                                                                                                "kind": "ScalarField",
                                                                                                "name": "expenseCost",
                                                                                                "storageKey": null
                                                                                            },
                                                                                            {
                                                                                                "alias": null,
                                                                                                "args": null,
                                                                                                "kind": "ScalarField",
                                                                                                "name": "expenseMarkup",
                                                                                                "storageKey": null
                                                                                            },
                                                                                            {
                                                                                                "alias": null,
                                                                                                "args": null,
                                                                                                "kind": "ScalarField",
                                                                                                "name": "expenseProfit",
                                                                                                "storageKey": null
                                                                                            }
                                                                                        ],
                                                                                        "storageKey": null
                                                                                    }
                                                                                ],
                                                                                "storageKey": null
                                                                            }
                                                                        ],
                                                                        "storageKey": null
                                                                    },
                                                                    (v10 /*: any*/),
                                                                    {
                                                                        "alias": null,
                                                                        "args": null,
                                                                        "concreteType": "RoleFinancialBreakdown",
                                                                        "kind": "LinkedField",
                                                                        "name": "roleFinancialBreakdown",
                                                                        "plural": true,
                                                                        "selections": [
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "kind": "ScalarField",
                                                                                "name": "breakdownType",
                                                                                "storageKey": null
                                                                            },
                                                                            {
                                                                                "alias": null,
                                                                                "args": null,
                                                                                "concreteType": "Role",
                                                                                "kind": "LinkedField",
                                                                                "name": "role",
                                                                                "plural": false,
                                                                                "selections": (v9 /*: any*/),
                                                                                "storageKey": null
                                                                            },
                                                                            (v10 /*: any*/)
                                                                        ],
                                                                        "storageKey": null
                                                                    }
                                                                ],
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ProjectIndicator_project"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Viewer"
    };
})();
node.hash = '0ba2b0986a7047e6d6e31a2d6c0753f4';
export default node;
