/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type reportedTimeTable_viewer$ref = any;
export type GLOBAL_FILTER_CONTEXT = "INVOICES" | "LABELS" | "PEOPLE" | "PLACEHOLDERS" | "PROJECTS" | "RESOURCES" | "SKILLS" | "TASKS" | "TIME_REGS" | "%future added value";
export type GLOBAL_FILTER_FIELD = "APPROVAL_STATUS" | "ASSIGNED" | "ASSIGNED_SELF" | "BILLABLE" | "BILLABLE_TIME_REG" | "BILL_FROM" | "BILL_TO" | "BLOCKED" | "BUG" | "CLIENT" | "CLIENT_GUEST_USERS" | "CLIENT_TIME_REG" | "COMPANY_TASK_ID" | "CONTACT" | "CREATED_AT" | "DEADLINE" | "DEPARTMENT" | "DEPARTMENT_TIME_REG" | "DEPENDENCIES" | "DESCRIPTION" | "END_DATE" | "END_DATE_PROJECT" | "END_DATE_TIME_REG" | "FILTER_BY_TIME_REG_SETTINGS" | "FOLLOWER" | "FORECAST" | "HIGH_PRIORITY" | "INTERNAL_TIME" | "INTERNAL_TIME_REG" | "INVOICED" | "LABELS" | "LABEL_TIME_REG" | "NAME" | "NON_CLIENT_TIME_REG" | "NON_DEPARTMENT_TIME_REG" | "NON_PHASE_TIME_REG" | "NON_PROJECT_TIME_REG" | "NON_ROLE_TIME_REG" | "NON_SPRINT_TIME_REG" | "NON_TASK_TIME_REG" | "NO_CONTACT" | "OWNER" | "PERMISSION_LEVEL" | "PERSON" | "PERSON_TIME_REG" | "PHASE_CATEGORY" | "PHASE_TIME_REG" | "PRIORITY_LEVEL" | "PROGRAM" | "PROJECT" | "PROJECT_BUDGET_TYPE" | "PROJECT_DEFAULT_PERIOD_BUDGET_TYPE" | "PROJECT_FOLLOWER" | "PROJECT_GROUP" | "PROJECT_GROUP_SPRINT" | "PROJECT_GROUP_STATUS" | "PROJECT_LABELS" | "PROJECT_MANUAL_PROGRESS" | "PROJECT_OWNER" | "PROJECT_PERSON" | "PROJECT_PHASE" | "PROJECT_SPRINT" | "PROJECT_STAGE" | "PROJECT_STATUS" | "PROJECT_TIME_REG" | "RATE_CARD" | "RECENT_ACTIVITY" | "RECENT_TASK_ACTIVITY" | "RECENT_TIME" | "REMAINING" | "RETAINER_PERIOD" | "ROLE" | "ROLE_TIME_REG" | "SET_DONE_AT" | "SKILL" | "SPRINT_CATEGORY" | "SPRINT_TIME_REG" | "STARRED" | "START_DATE" | "START_DATE_PROJECT" | "START_DATE_TIME_REG" | "STATUS" | "SUB_TASKS" | "TASK" | "TASK_APPROVED" | "TASK_HIERARCHY" | "TASK_LEVEL" | "TASK_TIME_REG" | "TEAM" | "TEAM_MEMBER" | "TEAM_TIME_REG" | "TIME_OFF" | "TIME_OFF_TIME_REG" | "TIME_REGS" | "UPDATED_AT" | "WITH_TASKS" | "WITH_TIME_REG" | "%future added value";
export type GLOBAL_FILTER_LOGICAL_OPERATOR = "AND" | "OR" | "%future added value";
export type GLOBAL_FILTER_OPERATOR = "ALL_IN" | "CONTAINS" | "CONTAINS_NOT" | "GREATER" | "GREATER_OR_EQUAL" | "IN" | "IS" | "IS_ALL" | "IS_NOT" | "IS_NOT_ALL" | "LESS" | "LESS_OR_EQUAL" | "NOT_ALL_IN" | "NOT_IN" | "%future added value";
export type TaskSearchQueryType = {|
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>
|};
export type GlobalSearchFilterType = {|
  field?: ?GLOBAL_FILTER_FIELD,
  operator?: ?GLOBAL_FILTER_OPERATOR,
  value?: ?$ReadOnlyArray<?string>,
  context?: ?GLOBAL_FILTER_CONTEXT,
  filters?: ?$ReadOnlyArray<?GlobalSearchFilterType>,
  logicalOperator?: ?GLOBAL_FILTER_LOGICAL_OPERATOR,
|};
export type reportedTimeTable_QueryVariables = {|
  searchQuery?: ?TaskSearchQueryType
|};
export type reportedTimeTable_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: reportedTimeTable_viewer$ref,
  |}
|};
export type reportedTimeTable_Query = {|
  variables: reportedTimeTable_QueryVariables,
  response: reportedTimeTable_QueryResponse,
|};
*/


/*
query reportedTimeTable_Query(
  $searchQuery: TaskSearchQueryType
) {
  viewer {
    actualPersonId
    component(name: "reported_time_table")
    ...reportedTimeTable_viewer_2rw059
    id
  }
}

fragment reportedTimeTable_viewer_2rw059 on Viewer {
  id
  company {
    totalMinutesRegistered(searchQuery: $searchQuery)
    currency
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchQuery",
    "type": "TaskSearchQueryType"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "reported_time_table"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"reported_time_table\")"
},
v3 = [
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "searchQuery"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "reportedTimeTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "reportedTimeTable_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "reportedTimeTable_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "kind": "ScalarField",
                "name": "totalMinutesRegistered",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "reportedTimeTable_Query",
    "operationKind": "query",
    "text": "query reportedTimeTable_Query(\n  $searchQuery: TaskSearchQueryType\n) {\n  viewer {\n    actualPersonId\n    component(name: \"reported_time_table\")\n    ...reportedTimeTable_viewer_2rw059\n    id\n  }\n}\n\nfragment reportedTimeTable_viewer_2rw059 on Viewer {\n  id\n  company {\n    totalMinutesRegistered(searchQuery: $searchQuery)\n    currency\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd370bb5cd49db70242528308db9e1b4e';

module.exports = node;
