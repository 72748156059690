import React from 'react';
import { NestedCard, FlexRow, StyledIcon, Text, SubHeading, FlexItem, FlexColumn, Table, Icon, Frame, } from '@forecast-it/design-system';
import { useIntl } from 'react-intl';
import Util from '../../forecast-app/shared/util/util';
const DeliverableIcon = () => React.createElement(StyledIcon, { icon: "deliverable", size: 'l' });
const SummaryCard = (props) => (React.createElement(FlexColumn, null,
    React.createElement(Text, { color: "auxiliary" }, props.label),
    React.createElement(SubHeading, null, props.value)));
const RolesEstimatesTable = ({ data, formatWithCurrency, }) => {
    const rows = data
        .map(roleBreakdown => {
        var _a;
        if (roleBreakdown && roleBreakdown.financialNumbers) {
            const { financialNumbers } = roleBreakdown;
            const [roundedHours, roundedValueOfService, roundedRevenue, roundedCost, roundedProfit] = [
                financialNumbers.baselineMinutes || 0,
                financialNumbers.baselineTimeAndExpenses || 0,
                financialNumbers.baselineRevenue || 0,
                financialNumbers.baselineCost || 0,
                financialNumbers.baselineProfit || 0,
            ].map(Util.roundFloatToTwoDecimals);
            return {
                roleName: (_a = roleBreakdown === null || roleBreakdown === void 0 ? void 0 : roleBreakdown.role) === null || _a === void 0 ? void 0 : _a.name,
                hours: `${roundedHours}h`,
                valueOfService: formatWithCurrency(roundedValueOfService),
                revenue: formatWithCurrency(roundedRevenue),
                cost: formatWithCurrency(roundedCost),
                profit: formatWithCurrency(roundedProfit),
            };
        }
        return null;
    })
        .filter(Boolean);
    return (React.createElement(Table, { data: rows, width: '100%' },
        React.createElement(Table.TextColumn, { accessorKey: 'roleName', header: 'Role', ellipsis: true, width: "20%" }),
        React.createElement(Table.TextColumn, { accessorKey: 'hours', header: 'Hours', align: Table.Align.right }),
        React.createElement(Table.TextColumn, { accessorKey: 'valueOfService', header: 'Value of Service', align: Table.Align.right }),
        React.createElement(Table.TextColumn, { accessorKey: 'revenue', header: 'Revenue', align: Table.Align.right }),
        React.createElement(Table.TextColumn, { accessorKey: 'cost', header: 'Cost', align: Table.Align.right }),
        React.createElement(Table.TextColumn, { accessorKey: 'profit', header: 'Profit', align: Table.Align.right })));
};
const ExpensesEstimatesTable = ({ data, formatWithCurrency, }) => {
    const rows = (data.edges || [])
        .filter(expense => expense && expense.node)
        .map(expense => {
        var _a, _b, _c, _d, _e, _f, _g;
        const revenueRaw = ((_a = expense === null || expense === void 0 ? void 0 : expense.node) === null || _a === void 0 ? void 0 : _a.expenseRevenue) || 0;
        const costRaw = ((_b = expense === null || expense === void 0 ? void 0 : expense.node) === null || _b === void 0 ? void 0 : _b.expenseCost) || 0;
        const [roundedRevenue, roundedCost, roundedMarkup, roundedProfit, roundedMargin] = [
            revenueRaw,
            costRaw,
            ((_c = expense === null || expense === void 0 ? void 0 : expense.node) === null || _c === void 0 ? void 0 : _c.expenseMarkup) || 0,
            ((_d = expense === null || expense === void 0 ? void 0 : expense.node) === null || _d === void 0 ? void 0 : _d.expenseProfit) || 0,
            revenueRaw ? ((revenueRaw - costRaw) / revenueRaw) * 100 : 0,
        ].map(Util.roundFloatToTwoDecimals);
        return {
            name: (_e = expense === null || expense === void 0 ? void 0 : expense.node) === null || _e === void 0 ? void 0 : _e.expenseName,
            categoryName: (_g = (_f = expense === null || expense === void 0 ? void 0 : expense.node) === null || _f === void 0 ? void 0 : _f.expenseCategory) === null || _g === void 0 ? void 0 : _g.name,
            revenue: formatWithCurrency(roundedRevenue),
            cost: formatWithCurrency(roundedCost),
            markup: `${roundedMarkup}%`,
            profit: formatWithCurrency(roundedProfit),
            margin: `${roundedMargin}%`,
        };
    });
    return (React.createElement(Table, { data: rows, width: '100%' },
        React.createElement(Table.TextColumn, { accessorKey: 'name', header: 'Name', ellipsis: true }),
        React.createElement(Table.TextColumn, { accessorKey: 'categoryName', header: 'Category', ellipsis: true, align: Table.Align.right }),
        React.createElement(Table.TextColumn, { accessorKey: 'revenue', header: 'Revenue', align: Table.Align.right }),
        React.createElement(Table.TextColumn, { accessorKey: 'cost', header: 'Cost', align: Table.Align.right }),
        React.createElement(Table.TextColumn, { accessorKey: 'markup', header: 'Markup', align: Table.Align.right }),
        React.createElement(Table.TextColumn, { accessorKey: 'profit', header: 'Profit', align: Table.Align.right }),
        React.createElement(Table.TextColumn, { accessorKey: 'margin', header: 'Margin', align: Table.Align.right })));
};
const DeliverableCard = ({ phaseDeliverable, currencySymbol, contextMenuOptions, }) => {
    const { formatMessage, formatNumber } = useIntl();
    const { financialNumbers, roleFinancialBreakdown, deliverable, phaseBaselineExpenses } = phaseDeliverable;
    const numberOfRoles = roleFinancialBreakdown.filter(breakdown => (breakdown === null || breakdown === void 0 ? void 0 : breakdown.breakdownType) === 'ROLE' && breakdown.role != null).length;
    const numberOfExpenses = phaseBaselineExpenses && phaseBaselineExpenses.edges ? phaseBaselineExpenses.edges.length : 0;
    const timeEstimatesSubHeading = `${formatMessage({ id: 'deliverables.edit_baseline.time_estimates' })} (${numberOfRoles})`;
    const formatCurrency = (value) => `${currencySymbol}${Util.roundFloatToTwoDecimals(value)}`;
    const [roundedHours, roundedvalueOfService, roundedRevenue, roundedCost, roundedprofit, roundedMargin] = [
        ((financialNumbers === null || financialNumbers === void 0 ? void 0 : financialNumbers.baselineMinutes) || 0) / 60,
        (financialNumbers === null || financialNumbers === void 0 ? void 0 : financialNumbers.baselineTimeAndExpenses) || 0,
        (financialNumbers === null || financialNumbers === void 0 ? void 0 : financialNumbers.baselineRevenue) || 0,
        (financialNumbers === null || financialNumbers === void 0 ? void 0 : financialNumbers.baselineCost) || 0,
        (financialNumbers === null || financialNumbers === void 0 ? void 0 : financialNumbers.baselineProfit) || 0,
        (financialNumbers === null || financialNumbers === void 0 ? void 0 : financialNumbers.baselineMargin) || 0,
    ].map(Util.roundFloatToTwoDecimals);
    const hours = `${roundedHours}h`;
    const valueOfService = formatCurrency(roundedvalueOfService);
    const revenue = formatCurrency(roundedRevenue);
    const cost = formatCurrency(roundedCost);
    const profit = formatCurrency(roundedprofit);
    const margin = roundedMargin.toString();
    const formatWithCurrency = (value) => Util.getFormattedNumberWithCurrency(currencySymbol, value, { formatNumber });
    const filteredRoleFinancialBreakdown = roleFinancialBreakdown.filter(roleBreakdown => roleBreakdown && roleBreakdown.role && roleBreakdown.role.name);
    const hasRolesTable = filteredRoleFinancialBreakdown.length > 0;
    const hasExpensesTable = phaseBaselineExpenses && phaseBaselineExpenses.edges && phaseBaselineExpenses.edges.length > 0;
    return (React.createElement(NestedCard, { colorTheme: "medium" },
        React.createElement(NestedCard.Header, { mainIcon: "deliverable", sectionName: formatMessage({ id: 'common.deliverable' }), name: (deliverable === null || deliverable === void 0 ? void 0 : deliverable.name) || '', contextMenuOptions: contextMenuOptions }),
        React.createElement(NestedCard.SummaryContainer, null,
            React.createElement(FlexRow, { justifyContent: "center" },
                React.createElement(SummaryCard, { label: formatMessage({ id: 'common.hours' }), value: hours }),
                React.createElement(SummaryCard, { label: formatMessage({ id: 'common.value_of_service' }), value: valueOfService }),
                React.createElement(SummaryCard, { label: formatMessage({ id: 'project_budget.revenue' }), value: revenue }),
                React.createElement(SummaryCard, { label: formatMessage({ id: 'common.cost' }), value: cost }),
                React.createElement(SummaryCard, { label: formatMessage({ id: 'common.profit' }), value: profit }),
                React.createElement(SummaryCard, { label: formatMessage({ id: 'common.margin' }), value: margin }))),
        React.createElement(NestedCard.CollapsibleContent, null,
            React.createElement(NestedCard.Content, null,
                React.createElement(FlexColumn, { gap: "m" },
                    React.createElement(FlexRow, null,
                        React.createElement(Icon, { icon: "deliverablesTimeEstimate", size: "l" }),
                        React.createElement(SubHeading, { size: '2' }, timeEstimatesSubHeading)),
                    hasRolesTable ? (React.createElement(RolesEstimatesTable, { data: filteredRoleFinancialBreakdown, formatWithCurrency: formatWithCurrency })) : (React.createElement(Frame, { centerContent: true },
                        React.createElement(Text, { color: "auxiliary" }, formatMessage({ id: 'deliverable.no_time_estimates' })))),
                    React.createElement(FlexRow, null,
                        React.createElement(Icon, { icon: "expensesNew", size: "l" }),
                        React.createElement(SubHeading, { size: '2' }, `${formatMessage({
                            id: 'deliverables.edit_baseline.expense_estimates',
                        })} (${numberOfExpenses})`)),
                    hasExpensesTable ? (React.createElement(ExpensesEstimatesTable, { data: phaseBaselineExpenses, formatWithCurrency: formatWithCurrency })) : (React.createElement(Frame, { centerContent: true },
                        React.createElement(Text, { color: "auxiliary" }, formatMessage({ id: 'deliverable.no_expense_estimates' })))))))));
};
export const PhaseCard = ({ phase, contextMenuOptions, currencySymbol, }) => {
    const { formatMessage } = useIntl();
    const { startDay, startYear, startMonth, deadlineDay, deadlineMonth, deadlineYear, name, phaseDeliverables } = phase;
    const numberOfDeliverables = phaseDeliverables && phaseDeliverables.edges
        ? phaseDeliverables.edges.filter(phaseDeliverable => phaseDeliverable && (phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.node) && (phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.node.deliverable)).length
        : 0;
    return (React.createElement(NestedCard, { colorTheme: "dark" },
        React.createElement(NestedCard.Header, { mainIcon: "phase", sectionName: formatMessage({ id: 'common.phase' }), name: name || '', contextMenuOptions: contextMenuOptions },
            React.createElement(FlexItem, null,
                React.createElement(FlexRow, { justifyContent: "flex-end" },
                    React.createElement(DeliverableIcon, null),
                    React.createElement(SubHeading, null, numberOfDeliverables))),
            React.createElement(FlexItem, null,
                React.createElement(FlexRow, { gap: "s" },
                    React.createElement(Text, null, formatMessage({ id: 'common.start' })),
                    React.createElement(SubHeading, null, `${startDay}/${startMonth}/${startYear}`))),
            React.createElement(FlexItem, null,
                React.createElement(FlexRow, { gap: "s" },
                    React.createElement(Text, null, formatMessage({ id: 'common.end' })),
                    React.createElement(SubHeading, null, `${deadlineDay}/${deadlineMonth}/${deadlineYear}`)))),
        React.createElement(NestedCard.CollapsibleContent, null, phaseDeliverables && phaseDeliverables.edges && phaseDeliverables.edges.length ? (phaseDeliverables.edges
            .map(phaseDeliverable => phaseDeliverable && phaseDeliverable.node ? (React.createElement(NestedCard.Content, null,
            React.createElement(DeliverableCard, { phaseDeliverable: phaseDeliverable === null || phaseDeliverable === void 0 ? void 0 : phaseDeliverable.node, currencySymbol: currencySymbol, contextMenuOptions: contextMenuOptions }))) : null)
            .filter(Boolean)) : (
        /* To be replaced by the add placeholder card */
        React.createElement("div", { style: {
                background: '#ffffff',
                height: '56px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            } },
            React.createElement("p", null, "SELECT DELIVERABLES CARD PLACEHOLDER"))
        /*  */
        ))));
};
