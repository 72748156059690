import React from 'react';
import {createFragmentContainer, graphql} from 'react-relay';
import {injectIntl} from 'react-intl';
import Dropdown from '../../../forecast-app/shared/components/dropdowns/dropdown';
import Util from '../../../forecast-app/shared/util/util';
import {Avatar, ComboBox, FlexRow, Text} from '@forecast-it/design-system';

const personDropdownRelayWrapper = props => {
	const {formatMessage} = props.intl;

	const personDropdownOptions = Util.getPersonsForDropdown(props.viewer.company.allPersons);

	if (props.withSkylineStyle) {
		const comboboxOptions = personDropdownOptions.map(personDropdownOption => ({
			id: personDropdownOption.value,
			name: personDropdownOption.label,
			...personDropdownOption,
		}));
		return (
			<div style={{width: `${props.customWidth}px`}}>
				<ComboBox
					items={comboboxOptions}
					width="flex"
					value={props.selectedPerson}
					onChange={id => {
						if (props.callback) {
							props.callback({value: id});
						}
					}}
					aria-label="Person Selector"
					data-cy={props.cy}
				>
					{item => (
						<ComboBox.Item textValue={item.name}>
							<FlexRow>
								<Avatar name={item.name} src={item.profilePictureId} size="s" />
								<Text>{item.name}</Text>
							</FlexRow>
						</ComboBox.Item>
					)}
				</ComboBox>
			</div>
		);
	}

	return (
		<Dropdown
			dataCy={props.cy}
			key={`dropdown-person-${props.index}`}
			isPersonSelector={true}
			hideLabel={true}
			label={formatMessage({id: 'overview_time.people-select-label'})}
			placeholder={formatMessage({id: 'common.select_person_placeholder'})}
			options={personDropdownOptions}
			onChange={props.callback ? props.callback : null}
			value={props.selectedPerson}
			customHeight={30}
			customWidth={props.customWidth}
			personImageSize={25}
			personNameStyle={{
				fontSize: '12px',
				fontWeight: 'normal',
				fontStyle: 'normal',
				fontStretch: 'normal',
				lineHeight: 'normal',
				letterSpacing: 'normal',
			}}
			tooltipEnabled={props.tooltipEnabled}
			tooltipProps={props.tooltipProps}
			userpilot={props.userpilot}
		/>
	);
};

const personDropdownRelayWrapperQuery = graphql`
	query personDropdownRelayWrapper_Query {
		viewer {
			actualPersonId
			component(name: "person_dropdown")
			...personDropdownRelayWrapper_viewer
		}
	}
`;

export {personDropdownRelayWrapperQuery};

export default injectIntl(
	createFragmentContainer(personDropdownRelayWrapper, {
		viewer: graphql`
			fragment personDropdownRelayWrapper_viewer on Viewer {
				id
				actualPersonId
				company {
					harvestEnabled
					allPersons(first: 10000, excludeClientUsers: true) {
						edges {
							node {
								id
								role {
									id
									name
								}
								email
								firstName
								lastName
								createdAt
								systemUser
								initials
								active
								profilePictureId
								profilePictureDefaultId
							}
						}
					}
				}
			}
		`,
	})
);
