import React, {useEffect} from 'react';
import {Table} from 'web-components';
import {useIntl} from 'react-intl';
import Util from '../../../../../forecast-app/shared/util/util';
import {isColumnLocked} from '../util/BudgetUtils';
import {CurrencyInputWrapper} from '../../../../inputs/currency_input_wrapper';
import CreateBulkFixedPriceLockMutation from '../../../../../mutations/create_bulk_fixed_price_lock_mutation';
import {dispatch, EVENT_ID} from '../../../../../containers/event_manager';
import moment from 'moment';
import Styled from 'styled-components/macro';
import * as tracking from '../../../../../tracking';
import {BUDGET_TYPE, PERIOD_BUDGET_TYPE} from '../../../../../constants';
import {trackEvent} from '../../../../../tracking/amplitude/TrackingV2';

const EditorWrapper = Styled.div`
  width: 100%;
  opacity:  ${props => (props.isUpdating ? '0.5' : '1.0')};  
	.currency-input {
		background-color: ${props => (props.hasManualValue ? '#F7F7FE' : '#FFF')}; // TODO: get the color from a theme provider somehow
	}
`;

const ManualRevenueRecognitionTableRow = ({data, rowData, project, tableColumnProps, isUpdating, setUpdating}) => {
	const intl = useIntl();
	const currencySymbol = Util.GetCurrencySymbol(rowData.currency);
	const fieldName = rowData.aggregate;

	const renderValue = value => {
		if (value !== undefined) {
			return Util.getFormattedNumberWithCurrency(currencySymbol, value, intl);
		} else {
			return '–';
		}
	};

	useEffect(
		() => {
			setUpdating(false);
		},
		rowData.dataArray.map(v => v.value)
	);

	const onSuccess = () => {
		dispatch(EVENT_ID.BUDGET_UPDATE_PROJECT);
	};

	const formatDateISO = date => (date ? date.format('YYYY-MM-DD') : '');

	function allowMoreManualRecognition() {
		if (
			project.budgetType === BUDGET_TYPE.TIME_AND_MATERIALS ||
			(project.budgetType === BUDGET_TYPE.RETAINER &&
				(project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.TIME_AND_MATERIALS ||
					project.defaultPeriodBudgetType === PERIOD_BUDGET_TYPE.FIXED_HOURS))
		) {
			return true;
		}

		const firstMonthStartDate = formatDateISO(rowData.dataArray[0].startDate);
		const lastMonthStartDate = formatDateISO(rowData.dataArray[rowData.dataArray.length - 2].startDate); // Last index is the total column so we look at the second from last column.
		const relevantFixedPriceLocks = project.fixedPriceLocks.edges.filter(
			e => e.node.endDate > firstMonthStartDate && e.node.startDate < lastMonthStartDate
		);
		const manuallyRecognizedMonthCount = [...new Set(relevantFixedPriceLocks.map(e => e.node.id))].length; // count distinct IDs (during mutation the same object has two edges)
		const totalMonths = rowData.dataArray.length - 1; // total column is not counted
		return totalMonths > manuallyRecognizedMonthCount + 1;
	}

	function isMonthManuallyRecognised(index) {
		const monthStartDate = rowData.dataArray[index].startDate;
		return project.fixedPriceLocks.edges.find(fixedPriceLock =>
			moment(fixedPriceLock.node.startDate).isSame(monthStartDate)
		);
	}

	const onManualRecognitionUpdated = (index, newValue, existingValue, expenseAmount) => {
		tracking.trackPageAction('Manual Recognized Amount Added');
		trackEvent('Manual Recognized Amount', 'Added');

		const periodData = rowData.dataArray[index];
		const valueChanged =
			Util.getFormattedCurrencyNumber(existingValue, intl) !== Util.getFormattedCurrencyNumber(newValue, intl);
		if (valueChanged && periodData && (isMonthManuallyRecognised(index) || allowMoreManualRecognition())) {
			setUpdating(true);
			Util.CommitMutation(
				CreateBulkFixedPriceLockMutation,
				{
					projectId: project.id,
					locks: [
						{
							startDate: formatDateISO(periodData.startDate.clone().startOf('month')),
							endDate: formatDateISO(periodData.startDate.clone().endOf('month')),
							locked: false,
							amount: newValue + expenseAmount,
							timeAmount: newValue,
						},
					],
				},
				onSuccess
			);
		}
	};

	return (
		<Table.Row cy={'row-' + fieldName} key={fieldName} {...tableColumnProps}>
			<Table.Column>{intl.formatMessage({id: 'project_budget.manual_revenue_recognition'})}</Table.Column>
			{rowData.dataArray.map((object, index) => {
				const isTotalsColumn = index === rowData.dataArray.length - 1;
				const isLocked = isColumnLocked(project.fixedPriceLocks, object);
				const isManuallyRecognised = isMonthManuallyRecognised(index);
				const isEditable = !isTotalsColumn && !isLocked && allowMoreManualRecognition();
				const expenseAmount = rowData.expenseAmounts[index];

				return (
					<Table.Column
						cy={'revenue-recognition-row-' + fieldName + '-column-' + data.columns[index].name}
						key={fieldName + object.value}
						bold={isTotalsColumn}
						negative={object.value < 0}
						greyedOut={isLocked}
					>
						<EditorWrapper isUpdating={isUpdating} hasManualValue={isManuallyRecognised}>
							{isEditable ? (
								<CurrencyInputWrapper
									intl={intl}
									value={object.value}
									disabled={isUpdating}
									callback={value => onManualRecognitionUpdated(index, value, object.value, expenseAmount)}
									currencySymbol={currencySymbol}
									className="currency-input"
								/>
							) : (
								<span>{renderValue(object.value)}</span>
							)}
						</EditorWrapper>
					</Table.Column>
				);
			})}
		</Table.Row>
	);
};

export default ManualRevenueRecognitionTableRow;
