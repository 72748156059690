/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DuplicateProjectDropdown_viewer$ref: FragmentReference;
declare export opaque type DuplicateProjectDropdown_viewer$fragmentType: DuplicateProjectDropdown_viewer$ref;
export type DuplicateProjectDropdown_viewer = {|
  +id: string,
  +projects: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: ?string,
        +companyProjectId: ?number,
        +customProjectId: ?string,
        +useManualAllocations: ?boolean,
        +useBaseline: ?boolean,
        +fullAccessToProject: ?boolean,
        +sageProject: ?{|
          +sageProjectId: ?string
        |},
      |}
    |}>
  |},
  +$refType: DuplicateProjectDropdown_viewer$ref,
|};
export type DuplicateProjectDropdown_viewer$data = DuplicateProjectDropdown_viewer;
export type DuplicateProjectDropdown_viewer$key = {
  +$data?: DuplicateProjectDropdown_viewer$data,
  +$fragmentRefs: DuplicateProjectDropdown_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DuplicateProjectDropdown_viewer",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10000
        }
      ],
      "concreteType": "ProjectTypeConnection",
      "kind": "LinkedField",
      "name": "projects",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProjectTypeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ProjectType",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "companyProjectId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "customProjectId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "useManualAllocations",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "useBaseline",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "fullAccessToProject",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SageProjectType",
                  "kind": "LinkedField",
                  "name": "sageProject",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "sageProjectId",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "projects(first:10000)"
    }
  ],
  "type": "Viewer"
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b25c27c40eb3d423697eed7534ecc029';

module.exports = node;
