/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DuplicateProjectDropdown_viewer$ref = any;
export type DuplicateProjectDropdown_QueryVariables = {||};
export type DuplicateProjectDropdown_QueryResponse = {|
  +viewer: ?{|
    +actualPersonId: ?string,
    +component: ?string,
    +$fragmentRefs: DuplicateProjectDropdown_viewer$ref,
  |}
|};
export type DuplicateProjectDropdown_Query = {|
  variables: DuplicateProjectDropdown_QueryVariables,
  response: DuplicateProjectDropdown_QueryResponse,
|};
*/


/*
query DuplicateProjectDropdown_Query {
  viewer {
    actualPersonId
    component(name: "duplicate_project_dropdown")
    ...DuplicateProjectDropdown_viewer
    id
  }
}

fragment DuplicateProjectDropdown_viewer on Viewer {
  id
  projects(first: 10000) {
    edges {
      node {
        id
        name
        companyProjectId
        customProjectId
        useManualAllocations
        useBaseline
        fullAccessToProject
        sageProject {
          sageProjectId
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actualPersonId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "name",
      "value": "duplicate_project_dropdown"
    }
  ],
  "kind": "ScalarField",
  "name": "component",
  "storageKey": "component(name:\"duplicate_project_dropdown\")"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DuplicateProjectDropdown_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DuplicateProjectDropdown_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DuplicateProjectDropdown_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 10000
              }
            ],
            "concreteType": "ProjectTypeConnection",
            "kind": "LinkedField",
            "name": "projects",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProjectTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProjectType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "companyProjectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customProjectId",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useManualAllocations",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "useBaseline",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullAccessToProject",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SageProjectType",
                        "kind": "LinkedField",
                        "name": "sageProject",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sageProjectId",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "projects(first:10000)"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": null,
    "metadata": {},
    "name": "DuplicateProjectDropdown_Query",
    "operationKind": "query",
    "text": "query DuplicateProjectDropdown_Query {\n  viewer {\n    actualPersonId\n    component(name: \"duplicate_project_dropdown\")\n    ...DuplicateProjectDropdown_viewer\n    id\n  }\n}\n\nfragment DuplicateProjectDropdown_viewer on Viewer {\n  id\n  projects(first: 10000) {\n    edges {\n      node {\n        id\n        name\n        companyProjectId\n        customProjectId\n        useManualAllocations\n        useBaseline\n        fullAccessToProject\n        sageProject {\n          sageProjectId\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ddf94762ce99b8214defd122a16bd20';

module.exports = node;
