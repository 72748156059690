import React, { useMemo } from 'react';
import { EMPHASIS, FlexColumn, StepCard, Text } from '@forecast-it/design-system';
import CustomScrollDiv from '../../forecast-app/shared/components/scroll-bars/custom_scroll_div';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Styled from 'styled-components';
import { projectUrl } from '../../directApi';
import { MODAL_TYPE, showModal } from '../../forecast-app/shared/components/modals/generic_modal_conductor';
import { PhaseCard } from '../baseline/PhaseDeliverablesCard';
import Util from '../../forecast-app/shared/util/util';
const PageWrapper = Styled.div `
	padding: 0 32px;
`;
const DescriptionContainer = Styled.div `
	width: 485px;
`;
const StepCardsContainer = Styled.div `
	display: flex;
	flex-direction: row;
	align-items: stretch;
	box-sizing: border-box;
	gap: 12px;
	margin: 24px 0 10px;
`;
const placeholderContextMenuOptions = [
    {
        name: 'option 1',
        onClick: () => {
            return;
        },
    },
    {
        name: 'option 2',
        onClick: () => {
            return;
        },
    },
    {
        name: 'option 3',
        onClick: () => {
            return;
        },
    },
];
const DeliverablesGuideSection = ({ project, company }) => {
    var _a, _b;
    const intl = useIntl();
    const history = useHistory();
    const { formatMessage } = intl;
    const currencySymbol = Util.GetCurrencySymbol((project === null || project === void 0 ? void 0 : project.rateCard) ? project === null || project === void 0 ? void 0 : project.rateCard.currency : company === null || company === void 0 ? void 0 : company.currency);
    const navigateToBaselineEdit = () => {
        history.push(`${projectUrl(project === null || project === void 0 ? void 0 : project.companyProjectId, project === null || project === void 0 ? void 0 : project.customProjectId)}/baseline/edit`);
    };
    const phases = (_a = project === null || project === void 0 ? void 0 : project.phases) === null || _a === void 0 ? void 0 : _a.edges;
    const deliverables = (_b = project === null || project === void 0 ? void 0 : project.deliverables) === null || _b === void 0 ? void 0 : _b.edges;
    const isPhasesCompleted = useMemo(() => phases.length > 0, [phases.length]);
    const isDeliverablesCompleted = useMemo(() => deliverables.length > 0, [deliverables.length]);
    const isHoursAndExpensesDisabled = useMemo(() => !isPhasesCompleted || !isDeliverablesCompleted, [isPhasesCompleted, isDeliverablesCompleted]);
    return (React.createElement(CustomScrollDiv, { horizontalcontent: true },
        React.createElement(PageWrapper, { "data-cy": 'baseline-deliverables-guide' },
            React.createElement(DescriptionContainer, null,
                React.createElement(Text, { size: '2' },
                    React.createElement(FormattedMessage, { id: 'deliverables.guide_section.description' }))),
            React.createElement(StepCardsContainer, null,
                React.createElement(StepCard, { completed: isPhasesCompleted, headerTitle: isPhasesCompleted ? formatMessage({ id: 'common.done' }) : formatMessage({ id: 'common.todo' }), headerIcon: isPhasesCompleted ? 'checkCircle' : undefined, title: formatMessage({ id: 'deliverables.guide_section.phases.title' }), icon: 'phase', button: {
                        label: formatMessage({ id: 'deliverables.guide_section.phases.button' }),
                        emphasis: isPhasesCompleted ? EMPHASIS.MEDIUM : EMPHASIS.HIGH,
                        onClick: () => showModal({
                            type: MODAL_TYPE.PHASE_MANAGEMENT_MODAL,
                            initialPhases: phases,
                            project,
                        }),
                    } },
                    React.createElement(FormattedMessage, { id: 'deliverables.guide_section.phases.description' })),
                React.createElement(StepCard, { completed: isDeliverablesCompleted, headerTitle: isDeliverablesCompleted ? formatMessage({ id: 'common.done' }) : formatMessage({ id: 'common.todo' }), headerIcon: isDeliverablesCompleted ? 'checkCircle' : undefined, title: formatMessage({ id: 'deliverables.guide_section.deliverables.title' }), icon: 'deliverable', button: {
                        label: formatMessage({ id: 'deliverables.guide_section.deliverables.button' }),
                        emphasis: isDeliverablesCompleted ? EMPHASIS.MEDIUM : EMPHASIS.HIGH,
                        onClick: () => showModal({
                            type: MODAL_TYPE.MANAGE_DELIVERABLES_MODAL,
                            deliverables,
                            projectId: project.id,
                        }),
                    } },
                    React.createElement(FormattedMessage, { id: 'deliverables.guide_section.deliverables.description' })),
                React.createElement(StepCard, { completed: false, disabled: isHoursAndExpensesDisabled, headerTitle: isHoursAndExpensesDisabled
                        ? formatMessage({ id: 'deliverables.guide_section.hours_and_expenses.header_title' })
                        : formatMessage({ id: 'common.todo' }), headerIcon: isHoursAndExpensesDisabled ? 'lockClosed' : undefined, title: formatMessage({ id: 'deliverables.guide_section.hours_and_expenses.title' }), icon: 'timeEstimate', button: {
                        label: formatMessage({ id: 'deliverables.guide_section.hours_and_expenses.button' }),
                        emphasis: EMPHASIS.HIGH,
                        onClick: () => navigateToBaselineEdit(),
                    } },
                    React.createElement(FormattedMessage, { id: 'deliverables.guide_section.hours_and_expenses.description' }))),
            React.createElement(FlexColumn, { gap: "m" }, phases === null || phases === void 0 ? void 0 : phases.map(phaseEdge => {
                if (phaseEdge && phaseEdge.node) {
                    const phase = phaseEdge.node;
                    return (React.createElement(PhaseCard, { phase: phase, currencySymbol: currencySymbol, contextMenuOptions: placeholderContextMenuOptions }));
                }
                return null;
            }).filter(Boolean)))));
};
export default DeliverablesGuideSection;
